import * as actionTypes from '../actions/actionTypes';

const initialState = {
    rowData: null
}

const historyReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SELECT_HISTORY:
         return {
             ...state,
             rowData : action.rowData
         }
         default:
         return state;
    }
};

export default historyReducer;