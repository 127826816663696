import { combineReducers } from 'redux';
import { reducer as oidcReducer } from 'redux-oidc';
import  entityReducer  from './entityReducer';
import documentReducer from './documentReducer';
import tabBarReducer from './tabBarReducer';
import changeControlReducer from './changeControlReducer';
import historyReducer from './historyReducer';
import searchReducer from './searchReducer';
import sectionReducer from './sectionReducer';
import prefsReducer from './prefsReducer';

const reducer = combineReducers(
    {
        oidc: oidcReducer,
        entity: entityReducer,
        document: documentReducer,
        tabBar: tabBarReducer,
        changeControl: changeControlReducer,
        history: historyReducer,
        search: searchReducer,
        section: sectionReducer,
        prefs: prefsReducer
    }
);

export default reducer;