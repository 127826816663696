import React, {Component} from 'react';
import Holder from '../../../hoc/holder';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { connect } from "react-redux";
import * as apis from '../../../utils/apis';
import * as lookupData from '../../../utils/lookupData';
import RequestLinkCell from '../../../components/RequestLinkCell/RequestLinkCell';
import notify from 'devextreme/ui/notify';
import ApprovalPopup from '../../../components/ApprovalPopup/ApprovalPopup';
import ViewChangePopup from '../../../components/ViewChangePopup/ViewChangePopup';
import CancelPopup from '../../../components/CancelPopup/CancelPopup';

import DataGrid, {
    Column,
    GroupPanel,
    FilterPanel,
    FilterRow,
    ColumnChooser,
    Scrolling,
    ColumnLookup,
    Pager,
    Paging
} from 'devextreme-react/ui/data-grid';

import { Template } from 'devextreme-react/core/template';
import * as actions from '../../../store/actions/actions';
import axios from 'axios';

const getMyChangesDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: apis.MANUALAPI + 'api/changecontrol/getchangerequestsmadebyuser',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
}

const getHeight = () => {
    return (window.innerHeight - 120)
}



class MyChanges extends Component {

    state = {
         approvals : 0
    }
   

    componentDidMount() {
        // check user has approvals here either from direct ownership, or delegation or both.
        // is use r a delgate no - yes does any of delgates have approvals to complete? - get them
        // does user have approvals to complete - get them
        // merge them.

        axios.get('api/changecontrol/checkchangeapprover')
        .then(response => {
            this.setState({approvals: response.data.count, loading: false});
        });

        window.addEventListener('resize', this.resize)

    }


    resize = () => {
        this.gridview.instance.option('height',getHeight());
    }

    switchClicked = (e) => {
         this.props.history.push('/approvals');
    }

    approvalClickHandler = (e) => {
        e.event.preventDefault();
        this.props.onShowingApprovalPopup(e.row.data);
    }

    cancelClickHandler = (e) => {
        e.event.preventDefault();
        this.props.onShowingCancelPopup(e.row.data);
    }

    hidingCancelPopupHandler = () => {
        this.props.onHidingCancelPopup();
    }

   
    hidingApprovalPopupHandler = () => {   
        this.props.onHidingApprovalPopup();

    }

    viewChangeClickHandler = (rowData) => {
        this.props.onShowingViewChangePopup(rowData)
    }

    hidingChangeRequestPopupHandler = () => {
        this.props.onHidingViewChangeForm();
    }

    cancelChangeRequest = (id) => {
     
      
          let data = {
            id: id,
          }
    
          axios.post('api/changecontrol/cancelchangerequest/', data)
          .then(response => {
             
              notify("Change request cancelled successfully.","success", 1500);
              this.props.onHidingCancelPopup();
    
          })
          .catch(error => {
              notify("Unable to cancel changerequest.","danger", 1500); 
              console.log(error);
        
          });
        
    }
      
    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    onToolbarPreparing = (e) =>  {

        if(this.state.approvals <= 0 ) {
            return;
        }

        e.toolbarOptions.items.unshift({
            location: 'before',
    
        },
        {
            location: 'after',
            widget: 'dxButton',
            options: {
              width: 200,
              text: 'Approvals',
              icon: 'check',
              onClick: this.switchClicked,
            }
          },
        );
      }

    isCancelIconVisible = (e) => {
        return !e.row.isEditing && (e.row.data.chainStatus === 2);
   
    }

    render() {
        let saveKey = 'mychanges';
        if(this.props.pagingEnabled === true) {
          saveKey = 'mychangesPaged';
        } 


       let grid =  
        <Holder>
        <DataGrid 
            dataSource={getMyChangesDataSource(this.props.user.access_token)}  
            rowAlternationEnabled={true}  
            noDataText="You have no open change requests."
            showBorders={true}
            showRowLines={true}
            showColumnLines={true}  
            columnHidingEnabled={false}
            allowColumnReordering={true}
            allowColumnResizing={true}
            stateStoring={{ enabled: 'true',type: 'localStorage', storageKey: saveKey }}
            onToolbarPreparing={(e) => this.onToolbarPreparing(e)}
            height={getHeight}
            width="100%" ref={(gv) => {this.gridview = gv}}
         >
         {this.props.pagingEnabled ? 
         <Paging defaultPageSize={10} enabled={true} /> : <Scrolling mode="virtual" />
         }

        <Pager
        showPageSizeSelector={true}
        allowedPageSizes={[10, 20]}
        showInfo={true} />
        <GroupPanel visible={true} />
        <FilterRow visible={true} />
        <FilterPanel visible ={true} />
        <ColumnChooser enabled={true} />
 
        <Column dataField={'id'} 
         allowFiltering={false}
         allowSorting={false}
         allowSearch={false}
         caption='Request'
         cellTemplate={'linkCellTemplate'} doViewChangeClicked={this.viewChangeClickHandler} dataType='number' visible={true} />
         
        <Column dataField={'chainStatus'} caption='Status' dataType='number' visible={true} >
            <ColumnLookup dataSource={lookupData.statusTypes} valueExpr='id' displayExpr='text' visible={true} />
        </Column> 
         
        <Column dataField={'documentIdentifier'} caption='Identifier' dataType='string' visible={true} />
        <Column dataField={'documentTitle'} caption='Title' dataType='string' visible={true}  />
        <Column dataField={'originator'} dataType='string' visible={true} />
        <Column dataField={'changeType'} dataType='number' visible={true}>
            <ColumnLookup dataSource={lookupData.changeTypes} valueExpr='id' displayExpr='text' visible={true} />
        </Column>   
        <Column dataField={'dateRequested'} dataType="date" format="dd/MM/y" visible={true} />
      
        <Column type={'buttons'}  allowReordering={false} showInColumnChooser={false} allowResizing={false}
          buttons={[ {
            text: 'Change Approvals',
            hint: 'Change Approvals',
            icon: 'ion ion-md-done-all',
            onClick: this.approvalClickHandler
          },
          {
            text: 'Cancel Change Request',
            hint: 'Cancel Change Request',
            icon: 'ion ion-md-close-circle',
            onClick: this.cancelClickHandler,
            visible: this.isCancelIconVisible,
          }
         
          ]} />
   
         <Template name={'linkCellTemplate'} render={RequestLinkCell} />
        </DataGrid>
        </Holder>;
        

        let cancelPopup = <CancelPopup
        popupVisible={this.props.showingCancelPopup}
 
        closeOnOutsideClick={true}
        hidingCancelPopupHandler={this.hidingCancelPopupHandler}
        cancelClicked={this.cancelChangeRequest}
        >
        </CancelPopup>

        if(this.props.showingCancelPopup === false)
        {
            cancelPopup = null;
        }


        let approvalPopup = <ApprovalPopup
        popupVisible={this.props.showingApprovalPopup}
 
        closeOnOutsideClick={true}
        hidingApprovalPopupHandler={this.hidingApprovalPopupHandler}
        approver={false}
        >
        </ApprovalPopup>

        if(this.props.showingApprovalPopup === false)
        {
            approvalPopup = null;
        }

        let viewChangePopup = <ViewChangePopup
        popupVisible={this.props.showingChangeRequestPopup}
       // rowData={this.props.rowData}
        closeOnOutsideClick={true}
        hidingChangeRequestPopupHandler={this.hidingChangeRequestPopupHandler}
        approver={false}
        >
        </ViewChangePopup>

        if(this.props.showingChangeRequestPopup === false)
        {
            viewChangePopup = null;
        }

        return( 
            <Holder>
                {grid}
                {approvalPopup}
                {viewChangePopup}
                {cancelPopup}
            </Holder>)
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.oidc.user,
        showingApprovalPopup: state.changeControl.showingApprovalPopup,
        showingChangeRequestPopup: state.changeControl.showingChangeRequestPopup,
        showingCancelPopup: state.changeControl.showingCancelPopup,
        pagingEnabled: state.prefs.pagingEnabled
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onShowingViewChangePopup: (rowData) => dispatch(actions.showViewChangePopup(rowData)),
        onShowingApprovalPopup: (rowData) => dispatch(actions.showApprovalPopup(rowData)),
        onShowingCancelPopup: (rowData) => dispatch(actions.showCancelPopup(rowData)),
        onHidingCancelPopup: () => dispatch(actions.hideCancelPopup()),
        onHidingApprovalPopup: () => dispatch(actions.hideApprovalPopup()),
        onHidingViewChangeForm: () => dispatch(actions.hideViewChangePopup())
     
    };
  }



  export default connect(mapStateToProps, mapDispatchToProps)(MyChanges);