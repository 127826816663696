import React, {Component} from 'react';
import { connect } from "react-redux";
import Form from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import axios from 'axios';
import './Preferences.css';
import { LoadPanel, ScrollView } from 'devextreme-react';
import * as actions from '../../store/actions/actions';

const getHeight = () => {
    return (window.innerHeight - 200)
}

class Preferences extends Component {

    state = {
        loading: true
    }

    resize = () => {
        this.gridview.instance.option('height',getHeight());
}


  
  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }


    componentDidMount() {
            axios.get('api/userPreferences/getpreferences')
            .then(response => {
               this.prefFormData = response.data;
               this.setState({loading: false})
            });  
            
            window.addEventListener('resize', this.resize)
    } 
   
    submitUpdatePrefs = (e) => {
        axios.post('api/userpreferences/UpdatePreferences', this.prefFormData)
        .then(response => {
            if(this.prefFormData.pagingEnabled === true) {
                this.props.onPagingOn();
            }
            else
            {
                this.props.onPagingOff();
            };
            
            notify("Preferences Updated","success", 1500); 

        })
        .catch(error => {
            notify("Preferences NOT Updated","warning", 1500); 
            console.log(error);
      
        });
    }

    prefFormData = {
        updates: false,
        reviews: false,
        changes: false,
        readAndSign: false,
        notifySubmitter: false,
        pagingEnabled: false
    };

    

    render() {

        let formItems = [{
            itemType: 'group',
            name: 'preferences',
            caption: "Preferences",
            colCount: 1,
            items: [{
                        dataField: "updates",
                        label: {text: 'Send me periodic notifications about updates to documents.'},
                        editorType: "dxCheckBox",
                        editorOptions: {
                            disabled: false,
                            width: "100%",
                        },
                    },
                    {
                        dataField: "reviews",
                        editorType: "dxCheckBox",
                        label: {text: 'Send me periodic notifications about documents I should review.'},
                        editorOptions: {
                            disabled: false,
                            width: "100%",
                        }
                    },
                    {
                        dataField: "changes",
                        editorType: "dxCheckBox",
                        label: {text: 'Send me periodic notifications about change requests requiring my action.'},
                        editorOptions: {
                            disabled: false,
                            width: "100%",
                        }
                    },
                    {
                        dataField: "readAndSign",
                        editorType: "dxCheckBox",
                        label: {text: 'Send me periodic notifications about outstanding read and sign requirements.'},
                        editorOptions: {
                            disabled: false,
                            width: "100%",
                        }
                    },
                    {
                        dataField: "notifySubmitter",
                        editorType: "dxCheckBox",
                        label: {text: 'Send me notifications about change requests I have submitted.'},
                        editorOptions: {
                            disabled: false,
                            width: "100%",
                        }
                    },
                    {
                        dataField: "pagingEnabled",
                        editorType: "dxCheckBox",
                        label: {text: 'Use paged grids in the manual.'},
                        editorOptions: {
                            disabled: false,
                            width: "100%",
                        }
                    },
                    {
                        itemType: "button",
                        visible: true,
                        horizontalAlignment: "right",
                        colSpan: 3,
                        buttonOptions: {
                            text: "Update",
                            type: "success",
                            useSubmitBehavior: false,
                            onClick: this.submitUpdatePrefs
                        }
                    },
    
                
            ],
        }];
     
    

        return <div id="prefsForm">
        <p>Note, settings you make in this area may be overridden at system level by the administrator.</p>
        <ScrollView height={getHeight} ref={(gv) => {this.gridview = gv}}>
        <Form formData={this.prefFormData} items={formItems}  />
        
        <LoadPanel visible={this.state.loading} />
        </ScrollView>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
       // user: state.oidc.user,
        prefs: state.prefs
       
    };
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        onPagingOn: () => dispatch(actions.pagingOn()),
        onPagingOff: () => dispatch(actions.pagingOff())
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(Preferences)