export const DEFAULTGROUP = "82776b4f-7900-4850-ac05-e9562abdd658";
export const DEFAULTCOMPANY = "3543c6ab-7f53-438d-be4f-409e779b41cf";
export const ROOTSECTION = "3274e3c3-79ae-4e14-bf9b-91eaeb660bdf"; //bosa
//export const ROOTSECTION = "f26f2b00-6fb8-4b20-975f-0a9e89c94b66"; // affinity


export const animOff = {
    show: {
        duration: 0
    },
    hide: {
        duration: 0
    }
}

export const changeStatement = "It is ensured that change(s) to the document/ form/ procedure are not covered by any existing documentation and that no documents are made obsolete by the changes made. Where a document/ form/ procedure requires CAA, TCCA or FAA approval, this has been sought and received.";