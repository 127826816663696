import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import { OidcProvider } from 'redux-oidc';
import userManager from './utils/userManager';
import store from './store/store';

import App from './App';


ReactDOM.render(
<Provider store={store}>
    <OidcProvider store={store} userManager={userManager}>
        <App />
    </OidcProvider>
</Provider>
, document.getElementById('root'));
