import React, {PureComponent} from 'react';
import {Popup, ScrollView } from 'devextreme-react';
import ChangeForm from '../ChangeForm/ChangeForm';
import { connect } from "react-redux";
//import * as actions from '../../store/actions/actions';
import Holder from '../../hoc/holder';
import {animOff} from '../../utils/appConfig'



class ChangeRequestPopup extends PureComponent {

    state={
        loading : true,
        docId : null,
        rowData: null,  
        title: "Initiate change request for " + this.props.rowData.identifier
    };
    
 

    componentDidUpdate() {
        if(this.state.docId !== this.props.rowData.id)
        {
            this.setState({rowData: this.props.rowData});
        }
    }

    getChangeMode = (m) => {
        if(m === "add") {
        
            this.setState({title: "Propose a new document."})
            
        }

        if(m ==="delete") {
            this.setState({title: "Propose deletion of " + this.props.rowData.identifier});
        }

        if(m === "update") {
            this.setState({title: "Propose an update to " + this.props.rowData.identifier});
        }

        if(m ==="cancel") {
            this.setState({title: "Initiate change request for " + this.props.rowData.identifier});
        }
       
    }

    
       
    render() {

    let popup = null;

    if(this.props.rowData) {
       
        popup =  
        <Popup visible={this.props.popupVisible} title={this.state.title} closeOnOutsideClick={true} onHidden={this.props.hidingPopupHandler} animation={animOff} showCloseButton={true} ref={(pop) => {this.pop = pop}} >
          <ScrollView>
            <ChangeForm editableCopy={this.props.rowData.editableCopy} externalApproval={this.props.rowData.externalApproval} rowData={this.props.rowData} changeMode={this.getChangeMode} />
          </ScrollView>
        </Popup>
    }
        return (
            <Holder>
           {popup}
           </Holder>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        rowData:state.document.rowData
    };
}

export default connect(mapStateToProps)(ChangeRequestPopup);