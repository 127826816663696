import React, {Component}  from 'react';
import { withRouter } from 'react-router-dom';
import Holder from '../../hoc/holder';
import MSLToolbar  from '../MSLToolbar/MSLToolbar';
import SideDrawer from '../SideDrawer/SideDrawer';
import MainMenu from '../MainMenu/MainMenu';
import Backdrop from '../Backdrop/backdrop';
import { connect } from "react-redux";
import axios from 'axios';
import * as actions from '../../store/actions/actions';
import * as apis from '../../utils/apis';

class Layout extends Component {

    state = {
        drawerShown: false,
        searchText: null
    }

    componentDidMount() {
      axios.get('api/userPreferences/getpreferences')
      .then(response => {
        if(response.data.pagingEnabled === true) {
          console.log(response.data.pagingEnabled);
         // dispatch action to turn it on.
         this.props.onPagingOn();
        }
        else
        {
          this.props.onPagingOff();
        }
         
      });  
      
    }

    toolBarSelectionHandler = (e) => {

        if(e.itemData.role) {
          switch(e.itemData.role) {
            case 'menu':
              this.setState({ drawerShown : true })
              break;
            case 'searchButton':
              this.props.history.push('/search');
              break;
            case 'hub':
              window.location.assign(apis.HUBHOME);
              break;
            case 'rshistory':
              this.props.history.push('/' + e.itemData.role);
              break;
            case 'preferences':
              this.props.history.push('/' + e.itemData.role);
              break;
            case 'suggest':
              this.props.history.push('/' + e.itemData.role);
              break;
            case 'about':
              this.props.history.push('/' + e.itemData.role);
              break;
            case 'incident':
              this.props.history.push('/' + e.itemData.role);
              break;
            case 'induction':
              this.props.history.push('/' + e.itemData.role);
              break;
            case 'querytoadmin':
              this.props.history.push('/' + e.itemData.role);
              break;
            case 'logout':
              this.props.history.push('/' + e.itemData.role);
              break;
            default: 
          }
        }  
    }


    closeMenuHandler = () => {
        this.setState({ drawerShown : false })
    }
  
    searchEnterPressHandler = (e) => {
      if(this.state.searchText !== e.component.option("value"))
      {
        this.setState({searchText: e.component.option("value")})
        this.props.onSearching(true);
        this.props.history.push('/search?searchPhrase=' + encodeURIComponent(e.component.option("value")));
      }
      
    }

    render() {
     
        return( 
        <Holder>
            <Backdrop show={this.state.drawerShown} clicked={this.closeMenuHandler}></Backdrop>
            <SideDrawer show={this.state.drawerShown}>
              <MainMenu menuSelected={this.props.sectionChanged} />
            </SideDrawer>
            <MSLToolbar 
            menuItems={this.props.toolbarMenu} 
            selected={this.toolBarSelectionHandler} 
            tabClicked={this.props.tabClicked} 
            searchEnterPress={this.searchEnterPressHandler}
            searchText={this.state.searchText}
            entityChanged={this.props.entityChanged}
            />
            <div style={{marginTop: "120px"}}>
            <main>
                {this.props.children} 
            </main>
            </div>
        </Holder>
        );
    }
}
const mapStateToProps = (state) => {
  return {
      user: state.oidc.user,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    
      onSearching: (searching) => dispatch(actions.searching(searching)),
      onPagingOn: () => dispatch(actions.pagingOn()),
      onPagingOff: () => dispatch(actions.pagingOff())
  };
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Layout));