import React, {Component, Fragment} from 'react';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { Form, LoadIndicator  } from 'devextreme-react';
import axios from 'axios';
import * as apis from '../../../utils/apis';
import { connect } from "react-redux";


const getSectionsDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: apis.MANUALAPI + 'api/clientsections/',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  const getOwnersDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: apis.MANUALAPI + 'api/clientjobtitles/',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  const getEntitiesDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: apis.MANUALAPI + 'api/companies/getallcompanies',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }


class ClientChangeForm extends Component {

    state =  {
        loading: true,
        error: false,
        selectedCCF: null 
    }

    componentDidMount() {
        if(this.props.woBookedOnTo) {
            
            if(this.state.selectedCCF !== this.props.historyRow.changeRequestId)
            axios.get('api/changecontrol/getAddForm' + '?changeId=' + this.props.historyRow.changeRequestId)
            .then(response => {
                this.addFormData = response.data;
                this.setState({ loading: false, selectedCCF: this.props.historyRow.changeRequestId });
            });
           
        }
    } 

    componentDidUpdate() {
        if(this.props.historyRow) {

            if(this.state.selectedCCF !== this.props.historyRow.changeRequestId)
            axios.get('api/changecontrol/getAddForm' + '?changeId=' + this.props.historyRow.changeRequestId)
            .then(response => {
                this.addFormData = response.data;
                this.setState({ loading: false, selectedCCF: this.props.historyRow.changeRequestId });
            });
        }
    }

  

    addFormData =  {
        identifier: '',
        title: '',
        changeReason: '',
        additionalText: '',
        originator: '',
        desiredEntity: '',
        sectionId: '',
        owners: [],
        primaryId: ''

    }

    render() {

        let addFormConfig = {
            items: [
                {
                    colSpan: 1,  
                    dataField: "identifier",
                    editorType: "dxTextBox",
                   
                    editorOptions: {
                        readOnly: true,
                    },
                    label : { text: "Desired Identifier"},
                },
                {
                    colSpan: 1,  
                    dataField: "title",
                    editorType: "dxTextBox",
                  
                    editorOptions: {
                        readOnly: true,
                    },
                    label : { text: "Desired Title"},
                   
                },
                {
                    colSpan: 1,  
                    dataField: "sectionId",
                    editorType: "dxSelectBox",
                 
                    editorOptions: {
                        dataSource: getSectionsDataSource(this.props.user.access_token),
                        valueExpr: "id",
                        searchExpr: "fullTitle",
                        displayExpr: "fullTitle",
                        searchEnabled: true,
                        height: "50%",
                        readOnly: true,
                    },
                    label : { text: "Desired Section"},
                   
                },
                {
                    colSpan: 1,  
                    dataField: "desiredEntity",
                    editorType: "dxSelectBox",
                 
                    editorOptions: {
                        dataSource: getEntitiesDataSource(this.props.user.access_token),
                        valueExpr: "id",
                        searchExpr: "shortDescription",
                        displayExpr: "shortDescription",
                        searchEnabled: true,
                        height: "50%",
                        readOnly: true,
                    },
                    label : { text: "Desired Doc Entity"},
                   
                },
                {
                    colspan: 1,
                    dataField: "owners",
                    editorType: "dxTagBox",
                 
                    editorOptions: {
                        dataSource: getOwnersDataSource(this.props.user.access_token),
                        valueExpr:"id",
                        displayExpr: "roleHolder",
                        searchExpr: "roleHolder",
                        readOnly: true,
                        
                    },
                    label : { text: "Desired Owners"},
                   
                },
                {
                    colspan: 1,
                    dataField: "primaryId",
                    editorType: "dxSelectBox",
                 
                    editorOptions: {
                        dataSource: getOwnersDataSource(this.props.user.access_token),
                        valueExpr:"id",
                        displayExpr: "roleHolder",
                        searchExpr: "roleHolder",
                        readOnly: true,
                    },
                    label : { text: "Desired Primary Owner"},
                   
                },
                {
                    colSpan: 2,  
                    dataField: "changeReason",
                    editorType: "dxTextArea",
                  
                    editorOptions: {
                        height:"100px" ,
                        readOnly: true,
                    },
                    label : { text: "Reason for Addition"},
                   
                },
                {
                    colSpan: 2,
                  
                    dataField: "additionalText",
                    editorType: "dxTextArea",
                  
                    editorOptions: {
                        height:"100px",
                        readOnly: true,
                    },
                    label : { text: "Additional Requests"},
                },
                {
                    colSpan: 2,  
                    dataField: "originator",
                    editorType: "dxTextBox",
                   
                    editorOptions: {
                        readOnly: true,
                    },
                    label : { text: "Originator"},
                    
                },
               


            ]
        };

        let updateFormConfig = {
            items: [
                {
                    colSpan: 1,  
                    dataField: "identifier",
                    editorType: "dxTextBox",
                   
                    editorOptions: {
                        readOnly: true,
                    },
                    label : { text: "Identifier"},
                    
                },
                {
                    colSpan: 1,  
                    dataField: "title",
                    editorType: "dxTextBox",
                   
                    editorOptions: {
                        readOnly: true,
                    },
                    label : { text: "Title"},
                   
                },
                {
                    colSpan: 2,
                  
                    dataField: "changeReason",
                    editorType: "dxTextArea",
                  
                    editorOptions: {
                        height:"100px",
                        readOnly: true, 
                    },
                    label : { text: "Reason for Change"},
                },
                {
                    colSpan: 2,
                  
                    dataField: "additionalText",
                    editorType: "dxTextArea",
                  
                    editorOptions: {
                        height:"100px" ,
                        readOnly: true,
                    },
                    label : { text: "Additional Requests"},
                },
                {
                    colSpan: 2,  
                    dataField: "originator",
                    editorType: "dxTextBox",
               
                    editorOptions: {
                        readOnly: true,
                    },
                    label : { text: "Originator"},
                    
                },
               

            ]
        };


        let deleteFormConfig = {
            items: [
                {
                    colSpan: 1,  
                    dataField: "identifier",
                    editorType: "dxTextBox",
                   
                    editorOptions: {
                        readOnly: true,
                    },
                    label : { text: "Identifier"},
                    
                },
                {
                    colSpan: 1,  
                    dataField: "title",
                    editorType: "dxTextBox",
                
                    editorOptions: {
                        readOnly: true,
                    },
                    label : { text: "Title"},
                   
                },
                 {
                    dataField: 'changeReason',
                    colSpan: 2,
                    editorType: "dxTextArea",
                 
                    editorOptions: {
                        height:"200px",
                        readOnly: true,
                    },
                    label : { text: "Reason for deletion"},
                   
                },
                {
                    colSpan: 2,  
                    dataField: "originator",
                    editorType: "dxTextBox",
               
                    editorOptions: {
                        readOnly: true,
                    },
                    label : { text: "Originator"},
                    
                },
               
                
            ]
        };

        let output = <p>Please select a history record.</p>

        if(this.props.historyRow) {

            if(this.props.historyRow.action === 'UPDATE') {
                output = <Fragment>
                <Form visible={!this.state.loading} height="100%"
                colCount={2}
                formData={this.addFormData}  
                items={updateFormConfig.items}
                ref={(form) => {this.addForm = form}}>
               </Form> 
                <LoadIndicator visible={this.state.loading} />
              </Fragment>
            }

            if(this.props.historyRow.action === 'DELETE') {
                output = <Fragment>
                <Form visible={!this.state.loading} height="100%"
                colCount={2}
                formData={this.addFormData}  
                items={deleteFormConfig.items}
                ref={(form) => {this.addForm = form}}>
               </Form> 
                <LoadIndicator visible={this.state.loading} />
              </Fragment>
            }

            if(this.props.historyRow.action === 'ADD') {
            output =  <Fragment>
            <Form visible={!this.state.loading} height="100%"
            colCount={2}
            formData={this.addFormData}  
            items={addFormConfig.items}
            ref={(form) => {this.addForm = form}}>
           </Form> 
            <LoadIndicator visible={this.state.loading} />
          </Fragment>
            }
        }
        return (
           <div>
               {output}
           </div>
             
         );
    }
}

const mapStateToProps = (state) => {
    return {
      user: state.oidc.user,
      historyRow: state.history.rowData,
    };
  }

export default connect(mapStateToProps)(ClientChangeForm)