import React, {Component} from 'react';
import LinkCell from '../../components/LinkCell/LinkCell';
import Holder from '../../hoc/holder';
import ReadSignPopup from '../../components/ReadSignPopup/ReadSignPopup';

import { createStore } from 'devextreme-aspnet-data-nojquery';
import { connect } from "react-redux";
import * as apis from '../../utils/apis';
import * as actions from '../../store/actions/actions';

import DataGrid, {
    Column,
    GroupPanel,
    FilterPanel,
    FilterRow,
    ColumnChooser,
    Scrolling,
    Pager,
    Paging
} from 'devextreme-react/ui/data-grid';

import { Template } from 'devextreme-react/core/template';


const getHeight = () => {
    return (window.innerHeight - 120)
}

const getReadAndSignHistoryDataSource = (token) => {
  return createStore({
    key: 'id',
    loadUrl: apis.MANUALAPI + 'api/userhistory/mandatoryread',
    onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers =  {'Authorization': "bearer " + token} 
      }
    }
  )
}

class ReadAndSignHistory extends Component {

  

      resize = () => {
        this.grid.instance.option('height',getHeight());
      }
    
      componentDidMount() {
        window.addEventListener('resize', this.resize)
      }
      
      componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
      }

      componentDidUpdate() {
          // we will be updating if the screen size changes, we're not interested in handling that.
          //console.log(this.props);
         
      }


     


      render() {
        let saveKey = 'rshistory';
        if(this.props.pagingEnabled === true) {
          saveKey = 'rshistoryPaged';
        }  
       
          return(
          <Holder>
          <DataGrid 
            dataSource={getReadAndSignHistoryDataSource(this.props.user.access_token)}  
            rowAlternationEnabled={true}  
            noDataText="You have no read and sign history yet."
            showBorders={true}
            showRowLines={true}
            showColumnLines={true}  
            columnHidingEnabled={false}
            allowColumnReordering={true}
            allowColumnResizing={true}
            stateStoring={{ enabled: 'true',type: 'localStorage', storageKey: saveKey }}
            height={getHeight}
            width="100%" ref={(gv) => {this.grid = gv}}
         >
         {this.props.pagingEnabled ? 
         <Paging defaultPageSize={10} enabled={true} /> : <Scrolling mode="virtual" />
         }

        <Pager
        showPageSizeSelector={true}
        allowedPageSizes={[10, 20]}
        showInfo={true} />
        <GroupPanel visible={true} />
        <FilterRow visible={true} />
        <FilterPanel visible ={true} />
        <ColumnChooser enabled={true} />
        <Column dataField={'identifier'} cellTemplate={'linkCellTemplate'} dataType='string' visible={true} />
        <Column dataField={'title'} dataType='string' visible={true} />
        <Column dataField={'issueNumber'} dataType='number' visible={false} />
        <Column dataField={'sectionTitle'} visible={false} dataType='string' />
        <Column dataField={'companyShortDescription'} caption='Entity' dataType='string' visible={false} />
        <Column dataField={'sectionEntityShortDescription'} caption='Section Entity' dataType="string" visible={false} />
        <Column dataField={'issueDate'} dataType="date" format="dd/MM/y" visible={false} />
        <Column dataField={'externalApproval'} caption="NAA Approval" visible={false} dataType='boolean'  />
        <Column dataField={'revisionDate'} format="dd/MM/y" dataType="date" visible={false} />
        <Column dataField={'dateSet'} format="dd/MM/y" dataType="date" visible={true} />
        <Column dataField={'dateRead'} format="dd/MM/y" dataType="date" visible={true} />
        <Column dataField={'revisionNumber'} dataType="number" visible={false} />
      
         <Template name={'linkCellTemplate'} render={LinkCell} />
        </DataGrid>
        <ReadSignPopup
        popupVisible={true}
        closeOnOutsideClick={true}
        hidingPopupHandler={this.hidingPopupHandler}
        readSignCompleted={this.readSignCompletedHandler}
        >
       </ReadSignPopup>
        </Holder>);
      }



}

const mapStateToProps = (state) => {
  return {
      user: state.oidc.user,
      pagingEnabled: state.prefs.pagingEnabled
  };
}

export default connect(mapStateToProps,null)(ReadAndSignHistory);