import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import TreeView from 'devextreme-react/ui/tree-view';
import axios from 'axios';
import * as actions from '../../store/actions/actions';

class MainMenu extends Component { 

    state ={
        menu: [],
        selectedEntityId: null,
        selectedSection: ''
    }


    componentDidMount() {
         axios.get('api/menu/getmenu?entityId=' + this.props.selectedEntity)
        .then(response => {
            this.setState({ menu: response.data})
        });
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (nextProps.selectedEntity !== this.state.selectedEntityId);
    }


    componentDidUpdate() {
        
        if(this.props.selectedEntity) {
            if(this.props.selectedEntity !== this.state.selectedEntityId) {
                axios.get('api/menu/getmenu?entityId=' + this.props.selectedEntity)
                .then(response => {
                this.setState({ menu: response.data, selectedEntityId: this.props.selectedEntity })
            }); 
          }
        }
    }

   
    sectionSelectedHandler = (e) => {
     
        if(!this.props.location.pathname.startsWith('/section')) {
            this.setState({selectedSection: null});
        }
        if(this.state.selectedSection !== e.itemData.sectionId) {
            this.props.onLoadingSection(true);
            this.props.history.push('/section/' + e.itemData.sectionId);
            this.setState({selectedSection : e.itemData.sectionId});
        }
    }

    render() {

        return(
            <div>
            <TreeView
            dataSource={this.state.menu}
            displayExpr="name"
            searchEnabled={true}
            searchEditorOptions={{placeholder: "Section Search"}}
            onItemClick={this.sectionSelectedHandler}
            />
        </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        user: state.oidc.user,
        selectedEntity: state.entity.entityId
    };
  }

  const mapDispatchToProps = dispatch => {
    return {
        onLoadingSection: (loading) => dispatch(actions.loadingSection(loading)),
        
    };
  }

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(MainMenu));

