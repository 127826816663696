import React from "react";
import { Button } from 'devextreme-react';
import userManager from '../../utils/userManager';
import IsAFTSAndroid from '../../utils/shared';

class LoginPage extends React.Component {

  

     onLoginButtonClick = (e) => {
        userManager.signinRedirect();
    }

    

    render() {
        if(IsAFTSAndroid())
        {
            return (
                <div style={styles.root}>
                    <h3 style={styles.typo}>Welcome to the Affinity FTS Company Manual</h3>
                    <p style={styles.typo}>Please log in to continue</p>
                    <Button type="default" onClick={this.onLoginButtonClick}>Login to Affinity FTS</Button>
                </div>
            );
        }
        else
        {
            userManager.signinRedirect();
            return null;
            
        }
      
    }
}

const styles = {
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
        flexShrink: 1
    },
    typo: {
        fontFamily: "roboto,roboto-fallback"
    }
};

export default LoginPage;