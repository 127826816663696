import React, {Component} from 'react';
import DeleteForm from './DeleteForm/DeleteForm';
import UpdateForm from './UpdateForm/UpdateForm';
import AddForm from './AddForm/AddForm';
import { connect } from "react-redux";
import * as actions from '../../../store/actions/actions';

class ViewChangeForm extends Component {
    
    
    closeFormHandler = () => {
        this.props.onHidingViewChangeForm();
    }

    
    render() {
        
       
        switch(this.props.rowData.changeType) {
            case 0: 
                return <DeleteForm id={this.props.rowData.id} formClosed={this.closeFormHandler} />
            case 1: 
                return <UpdateForm id={this.props.rowData.id} formClosed={this.closeFormHandler} />
            case 2: 
                return <AddForm id={this.props.rowData.id} formClosed={this.closeFormHandler} />
            default:
                return null;
        }
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onHidingViewChangeForm: () => dispatch(actions.hideViewChangePopup())
    };
  }

export default connect(null, mapDispatchToProps)(ViewChangeForm)