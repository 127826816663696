import * as actionTypes from '../actions/actionTypes';

const initialState = {
    showingAddForm: false,
    showingDeleteForm: false,
    showingUpdateForm: false,
    modeSelected: false,
    showingChangeRequestPopup: false,
    showingApprovalPopup: false,
    showingCancelPopup: false,
    rowData: null
};

const changeControlReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SHOW_ADD_FORM:
            return {
                ...state,
                showingAddForm: true,
                modeSelected: true
            }
        case actionTypes.SHOW_UPDATE_FORM:
            return {
                ...state,
                showingUpdateForm: true,
                modeSelected: true
            }
        case actionTypes.SHOW_DELETE_FORM:
            return {
                ...state,
                showingDeleteForm: true,
                modeSelected: true
            }
        case actionTypes.HIDE_ADD_FORM:
            return {
                ...state,
                showingAddForm: false,
                modeSelected: false
            }
        case actionTypes.HIDE_DELETE_FORM:
            return {
                ...state,
                showingDeleteForm: false,
                modeSelected: false
            }
        case actionTypes.HIDE_UPDATE_FORM:
            return {
                ...state,
                showingUpdateForm: false,
                modeSelected: false
            }
        case actionTypes.RESET_CHANGE_FORM:
            return {
                ...state,
                showingAddForm: false,
                showingUpdateForm: false,
                showingDeleteForm: false,
                modeSelected: false
            }
        case actionTypes.SHOW_APPROVAL_POPUP:
            return {
                ...state,
                showingApprovalPopup: true,
                rowData: action.rowData
            }
        case actionTypes.SHOW_CANCEL_POPUP:
            return {
                ...state,
                showingCancelPopup: true,
                rowData: action.rowData
            }
        case actionTypes.HIDE_CANCEL_POPUP:
            return {
                ...state,
                showingCancelPopup: false,
                rowData: null
            }
        case actionTypes.HIDE_APPROVAL_POPUP:
            return {
                ...state,
                showingApprovalPopup: false,
                rowData: null
            }
        case actionTypes.SHOW_VIEW_CHANGE_POPUP:
            return {
                ...state,
                showingChangeRequestPopup: true,
                rowData: action.rowData,
            }
        case actionTypes.HIDE_VIEW_CHANGE_POPUP:
            return {
                ...state,
                showingChangeRequestPopup: false,
                rowData: null
            }
        default:
        return state;
    }
};

export default changeControlReducer;