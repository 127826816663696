import React, {Component} from 'react';
import { Form, LoadIndicator } from 'devextreme-react';
import axios from 'axios';
import Holder from '../../../../hoc/holder';

class DeleteForm extends Component {

    state={
        loading: true,
        deleteFormData: {
            identifier: '',
            title: '',
            changeReason: '',
            originator: '',
        }
    };
    
    componentDidMount() {
        axios.get('api/changecontrol/getDeleteForm' + '?changeId=' + this.props.id)
        .then(response => {
            this.setState({deleteFormData: response.data, loading: false });
        });
    }


    render() {
    
        let deleteFormConfig = {
            items: [
                {
                    colSpan: 1,  
                    dataField: "identifier",
                    editorType: "dxTextBox",
                
                    editorOptions: {
                        readOnly: true,
                    },
                    label : { text: "Identifier"},
                    
                },
                {
                    colSpan: 1,  
                    dataField: "title",
                    editorType: "dxTextBox",
                   
                    editorOptions: {
                        readOnly: true,
                    },
                    label : { text: "Title"},
                   
                },
                 {
                    dataField: 'changeReason',
                    colSpan: 2,
                    editorType: "dxTextArea",
                  
                    editorOptions: {
                        height:"200px",
                        readOnly: true,
                    },
                    label : { text: "Reason for deletion"},
                   
                },
                {
                    colSpan: 2,  
                    dataField: "originator",
                    editorType: "dxTextBox",
                  
                    editorOptions: {
                        readOnly: true,
                    },
                    label : { text: "Originator"},
                    
                },
               
                {
                    itemType: "button",
                    horizontalAlignment: "right",
                    colSpan: 2,
                    buttonOptions: {
                        text: "OK",
                        type: "default",
                        useSubmitBehavior: false,
                        onClick: this.props.formClosed
                    }
                }
            ]
        };


        
        return <Holder>
            <Form visible={!this.state.loading}
            height="100%"
            colCount={2}
            formData={this.state.deleteFormData} 
            items={deleteFormConfig.items}
            ref={(form) => {this.deleteForm = form}}>
            </Form> 
            <LoadIndicator visible={this.state.loading} />
            </Holder> 

        
       

      
    }
}

export default DeleteForm;