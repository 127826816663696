import React, {Component, Fragment} from 'react';
import Form from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import axios from 'axios';
import './Query.css';
import {Popup, Button} from 'devextreme-react';


class Query extends Component {

    formData = {
        suggestion: '',
        mailMe: false,
    };

    closePopup = (e) => {
        e.event.preventDefault();
        this.okpopup.instance.hide();
        this.sugform.instance.resetValues();
    }

   

    submitQuery = (e) => {
        var result = e.validationGroup.validate();

        if(result.isValid) {
            axios.post('api/userquery/sendquery', this.formData)
            .then(response => {
                this.okpopup.instance.show();
                notify("Query submitted","success", 1500); 
               
            })
            .catch(error => {
                notify("Query NOT submitted","warning", 1500); 
                console.log(error);
          
            });
        }
    }

    formItems = [{
        itemType: 'group',
        name: 'preferences',
        caption: "Submit a query to the administrator",
        colCount: 1,
        items: [{
                    dataField: "suggestion",
                    editorType: "dxTextArea",
                    label: { text: 'Please type your query below'},
                    editorOptions: {
                        disabled: false,
                        width: "100%",
                        height: 250,
                    },
                    validationRules: [{
                        type: "required",
                        message: "You query is required."
                    },
                    ]
                },
                {
                    dataField: "mailMe",
                    editorType: "dxCheckBox",
                    label : { text : 'E-mail me a copy' },
                    editorOptions: {
                        disabled: false,
                        width: "100%"
                    }
                },
              
                {
                    itemType: "button",
                    visible: true,
                    horizontalAlignment: "right",
                    colSpan: 3,
                    buttonOptions: {
                        text: "Submit",
                        type: "success",
                        useSubmitBehavior: false,
                        onClick: this.submitQuery
                    }
                },

            
        ],
    }];
    
   
    render() {
        return ( 
        <Fragment>
        <div id="queryForm">
     
      
        <Form formData={this.formData} items={this.formItems} ref={(form) => {this.sugform = form}} >
        </Form>
        </div>
        <Popup  title="Thank You" onHiding={this.resetForm} ref={(popup) => {this.okpopup = popup}} width={400} height={200}  >
        <p>Thank you - Your query has been passed to the admin team.</p>
           <Button text="OK" type="success" onClick={this.closePopup} ></Button>
        </Popup>
        </Fragment>
        )
    }
}

export default Query