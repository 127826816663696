import React, {PureComponent} from 'react';
import {Popup, TextArea, Button, Validator} from 'devextreme-react';
import { connect } from "react-redux";
import * as actions from '../../store/actions/actions';
import Holder from '../../hoc/holder';
import { animOff } from '../../utils/appConfig';
import axios from 'axios';

class ReviewPopup extends PureComponent {

    state={
        reviewText: '',
        read: null,
    };

    componentDidUpdate() {

        if (this.props.rowData !== null) {

            if (true) {
                axios.get('api/clientrecency/' + this.props.rowData.id)
                    .then(response => {
                        this.setState({ read: response.data.result });

                    });
            }
        }

    }

    validationCallback = () => {
        return this.state.read;
    }

    validationRules = {
        review: [
            { type: 'required', message: 'Review text is required.' },
            { type: 'custom', message: 'You must have recently read this document in order to review it.', validationCallback: this.validationCallback }
        ],

    }

    reviewTextChangedHandler = (event) => {
        this.setState({
            reviewText: event.value
        });
    }

    hiddenHandler = (event) => {
        this.setState({ reviewText: '',read: null });
        this.props.hidingPopupHandler();
    }

    changeControlHandler = (rowData) => {
        this.setState({ reviewText: '', read: null });
        this.props.hidingPopupHandler();
        this.props.onShowingChangePopup(rowData);
    }

    completeReviewHandler = (params) => {
        const result = params.validationGroup.validate();


        if (result.isValid) {
       
           
            this.props.onReviewingDocument(this.props.rowData.id, { reviewText: this.state.reviewText, daysRemaining: this.props.rowData.daysRemaining });

            params.validationGroup.reset();
            this.props.reviewCompleted();
        }
        else
        {
            this.textArea.instance.focus();
        }
 
    }

    render() {

        let popup = null;

        if(this.props.rowData) {
        popup =  <Popup visible={this.props.popupVisible} title={"Review Document " + this.props.rowData.identifier} closeOnOutsideClick={true} onHidden={this.hiddenHandler} animation={animOff} showCloseButton={true}>
       
        <TextArea height="calc(100% - 60px)" placeholder="Enter review text - this text updates the document history." value={this.state.reviewText} onValueChanged={this.reviewTextChangedHandler} valueChangeEvent='focusout' ref={(ta) => {this.textArea = ta}}>
        <Validator validationRules={this.validationRules.review} />
        </TextArea>
        
        <div style={{float : "right", marginTop: "10px"}}>
        <span><Button text="Change Control" type="default" onClick={() => this.changeControlHandler(this.props.rowData)}></Button></span>
        <span style ={{marginLeft: "15px"}}><Button text="Cancel" type="danger" onClick={this.hiddenHandler}></Button></span>
        <span style ={{marginLeft: "15px"}}><Button text="Complete Review" type="success" onClick={this.completeReviewHandler}></Button></span>
        </div>
        </Popup>
        }

        return (
            <Holder>{popup}</Holder>
        );
    }

}


const mapDispatchToProps = dispatch => {
    return {
        onReviewingDocument: (docId,reviewText) => dispatch(actions.reviewDocAsync(docId,reviewText)),
        onShowingChangePopup: (rowData) => dispatch(actions.showChangePopup(rowData)),
        onHidingChangePopup: () => dispatch(actions.hideChangePopup()),
    };
  }

 const mapStateToProps = (state) => {
    return {
        rowData:state.document.rowData
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(ReviewPopup);