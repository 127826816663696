import React from 'react';
import Holder from '../../hoc/holder';
import './RequestLinkCell.css';
import { Button } from 'devextreme-react';

const requestLinkCell = (props) => {    
    return (
        <Holder>
            <Button type='default' onClick={() => props.column.doViewChangeClicked(props.data)}>View</Button>
           
        </Holder>
    );

}



export default requestLinkCell;