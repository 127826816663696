import React from 'react';
import HighLighter from "react-highlight-words";
import './SearchResult.css';
import SearchCommandCell from '../SearchCommandCell/SearchCommandCell';


const searchResult =(props) => {

    return (
        <div>
            <span className="SearchCommandCell"><SearchCommandCell favouriteClicked={props.favouriteClicked}
            historyClicked={props.historyClicked}
            ownerClicked={props.ownerClicked}
            changeClicked={props.changeClicked} result={props.result} /></span>
            <p>
            <a className="msl-doclink" onClick={props.clicked}>{props.result.identifier + ' - ' + props.result.title + ' [' + props.result.companyShortDescription + ']'}</a>
            <span>&nbsp;In section: {props.result.sectionTitle}</span>
            </p>
            <p>
                <HighLighter
                highlightClassName="SearchMatch"
                searchWords={props.searchPhrase}
                autoEscape={true}
                textToHighlight={props.result.result} /><br />
            </p>
           
            <hr />
        </div>
    )

}

export default searchResult;