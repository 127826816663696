import React, {PureComponent} from 'react';
import {Popup, ScrollView} from 'devextreme-react';
import { connect } from "react-redux";
import {animOff} from '../../utils/appConfig'
import ApprovalStatus from './ApprovalStatus/ApprovalStatus';
import axios from 'axios';
import { LoadPanel } from 'devextreme-react/tree-list';
import ApprovalForm from '../ApprovalForm/ApprovalForm';


class ApprovalPopup extends PureComponent {

        state = {
            changeId: null,
            approvals: [],
            loading: true 
        }

        componentDidMount() {   
                axios.get('api/changecontrol/GetApprovalStatusForChange' + '?changeId=' + this.props.rowData.id)
                .then(response => {
                    this.setState({approvals: response.data, loading: false, changeId: this.props.rowData.id});
                })
                .catch(error => {
                    console.log(error);    
                });
        }
        

        componentDidUpdate() {
            if(this.props.rowData.id !== this.state.changeId) {
                axios.get('api/changecontrol/GetApprovalStatusForChange' + '?changeId=' + this.props.rowData.id)
                .then(response => {
                    this.setState({approvals: response.data, loading: false, changeId: this.props.rowData.id});
                })
                .catch(error => {
                    console.log(error);    
                });
            }
        }
        render() {
        
            if(this.state.loading === true) {
                return <LoadPanel></LoadPanel>
            }
            else
            {

           

            if(this.props.approver === true) {
                return <Popup 
                visible={this.props.popupVisible}
                closeOnOutsideClick={this.props.closeOnOutsideClick}
                onHidden={this.props.hidingApprovalPopupHandler}
                animation={animOff} title={'Process Change'} showCloseButton={true}
                 >
                <ScrollView>
                    <ApprovalStatus approvals={this.state.approvals} />
                    <ApprovalForm changeRequestId={this.props.rowData.id} />
                </ScrollView>
               </Popup>
            }




            return <Popup 
                    visible={this.props.popupVisible}
                    closeOnOutsideClick={this.props.closeOnOutsideClick}
                    onHidden={this.props.hidingApprovalPopupHandler}
                    animation={animOff} title="Request Status" showCloseButton={true} >
                    <ScrollView>
                    <ApprovalStatus approvals={this.state.approvals} />
                    </ScrollView>

                   </Popup>
        }
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.oidc.user,
        rowData: state.changeControl.rowData
    };
}


export default connect(mapStateToProps)(ApprovalPopup);