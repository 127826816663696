import React, {PureComponent} from 'react';
import { Form , FileUploader } from 'devextreme-react';
import { connect } from "react-redux";
import * as actions from '../../store/actions/actions';
import ChangeModeSelector from './ChangeModeSelector/changeModeSelector';
import axios from 'axios';
import Holder from '../../hoc/holder';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import * as apis from '../../utils/apis';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';
import * as config from '../../utils/appConfig';
import './ChangeForm.css';


const getSectionsDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: apis.MANUALAPI + 'api/clientsections/',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  const getOwnersDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: apis.MANUALAPI + 'api/clientjobtitles/',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  const getGroupsDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: apis.MANUALAPI + 'api/groups/getusergroups',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  const getEntitiesDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: apis.MANUALAPI + 'api/companies/getallcompanies',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

class ChangeForm extends PureComponent {


    state = {
        changeReason: '',
        desiredSection: null,
        loading: true,
        checkoutData: null,
     };

   componentDidMount() {
       

        axios.get('api/checkout/checkstatus/' + this.props.rowData.id)
        .then(response => {
            this.setState({checkoutData: response.data, loading: false });
        });
    } 

    componentDidUpdate() {
        

        if(this.state.checkoutData === null)
        axios.get('api/checkout/checkstatus/' + this.props.rowData.id)
        .then(response => {
            this.setState({ checkoutData: response.data, loading: false});
        });
    }

    addClickHandler = ()  => {
        this.props.changeMode("add");
        let result = confirm("<i>"+ config.changeStatement + "</i>", "Confirm New Document");
        result.then((dialogResult) => {
          //  console.log(dialogResult);
            if(dialogResult === true) {
                this.props.onShowingAddForm();
            }
            else
            {
                this.props.changeMode("cancel");
                return;
            }
        });
        
    }

    deleteClickHandler =() => {
        this.props.changeMode("delete");
        let result = confirm("<i>"+ config.changeStatement + "</i>", "Confirm Document Deletion");
        result.then((dialogResult) => {
            console.log(dialogResult);
            if(dialogResult === true) {
                this.props.onShowingDeleteForm();
            }
            else
            {
                this.props.changeMode("cancel");
                return;
            }
        });
        
    }

    updateClickHandler = () => {
        this.props.changeMode("update");
        let result = confirm("<i>"+ config.changeStatement + "</i>", "Confirm Document Update");
        result.then((dialogResult) => {
            console.log(dialogResult);
            if(dialogResult === true) {
                this.props.onShowingUpdateForm();
            }
            else
            {
                this.props.changeMode("cancel");
                return;
            }
        });

        
    }

    fileUploaded = (e) => {
        this.changeForm.instance.option("formData").fileName=e.file.name;
        this.changeUploader.instance.option('isValid', true);
    }

    fileAddUploaded = (e) => {
        this.addForm.instance.option("formData").fileName=e.file.name;
        this.addUploader.instance.option('isValid', true);
    }

    checkOutClickedHandler = (docId) => {
            axios.get('api/checkout/geteditablelink/' + docId)
            .then(response => {
                window.location.href = (response.data);
                
                let checkoutDataCopy = {
                    ...this.state.checkoutData
                }

                checkoutDataCopy.checkedOut = true;
                checkoutDataCopy.checkOutBy = this.props.user.profile.sub;
                checkoutDataCopy.checkedOutName = this.props.user.profile.fullname;
                this.setState({checkoutData: checkoutDataCopy});
                this.props.onHidingChangePopup();
                notify("Document Checked Out","success", 1500);              
            })
            .catch(error => {
                console.log(error)
            });

    }

    validateIdentifier = (params) => {
        console.log(params);
        let selectedEntity = this.addForm.instance.option("formData").desiredEntity;
        axios.post('api/validateIdentifier', { identifier: params.value, desiredEntity: selectedEntity} )
        .then(response => {
            params.rule.isValid = response.data.result;
            params.rule.message = response.data.message;
            params.validator.validate();      
          })
        .catch(error => {
            console.log(error);
      
        });
        // Validation result until the response is received

        return false;
    }

    submitDeleteRequest = (params) => {
      var result = params.validationGroup.validate();
      
      if(result.isValid) {
          this.deleteForm.instance.option("formData").documentId = this.props.rowData.id;
     

          axios.post('api/changecontrol/deleterequest',this.deleteForm.instance.option("formData") )
          .then(response => {
            let checkoutDataCopy = {
                ...this.state.checkoutData
            }

            checkoutDataCopy.checkedOut = true;
            checkoutDataCopy.checkOutBy = this.props.user.profile.sub;
            checkoutDataCopy.checkedOutName = this.props.user.profile.fullname;
            checkoutDataCopy.changeInProgress = true;
            this.setState({checkoutData: checkoutDataCopy});     
            this.props.onHidingDeleteForm(); 
            this.props.onHidingChangePopup();
            notify("Change Request Submitted","success", 1500);   
          })
          .catch(error => {
              console.log(error);
        
          });
      }
 
    }

    submitChangeRequest = (params) => {
        if(this.changeForm.instance.option("formData").fileName === null) {
            this.changeUploader.instance.option('isValid', false);
            this.changeUploader.instance.option('validationError', { message: "Please upload a file." });
            return;
        }
        var result = params.validationGroup.validate();
        
        if(result.isValid) {
            this.changeForm.instance.option("formData").documentId = this.props.rowData.id;
 

            axios.post('api/changecontrol/changerequest',this.changeForm.instance.option("formData") )
            .then(response => {
                let checkoutDataCopy = {
                    ...this.state.checkoutData
                }
    
                checkoutDataCopy.checkedOut = true;
                checkoutDataCopy.checkOutBy = this.props.user.profile.sub;
                checkoutDataCopy.checkedOutName = this.props.user.profile.fullname;
                checkoutDataCopy.changeInProgress = true;
                this.setState({checkoutData: checkoutDataCopy});     
                this.props.onHidingUpdateForm();  
                this.props.onHidingChangePopup();
                notify("Change Request Submitted","success", 1500);   
            })
            .catch(error => {
                console.log(error);
          
            });
        }
   
      }

      submitAddChangeRequest = (params) => {

        if(this.addForm.instance.option("formData").fileName === null) {
            this.addUploader.instance.option('isValid', false);
            this.addUploader.instance.option('validationError', { message: "Please upload a file." });
            return;
        }

        var result = params.validationGroup.validate();
        
        if(result.isValid) {
            

  
            axios.post('api/changecontrol/addrequest',this.addForm.instance.option("formData") )
            .then(response => {
                  
                this.props.onHidingAddForm();  
                this.props.onHidingChangePopup();
                notify("Change Request Submitted","success", 1500);   
            })
            .catch(error => {
                console.log(error);
          
            });
        }
   
      }

    entitySelection = (e) => {
        if(e.selectedItem !== null) {
            this.addForm.instance.getEditor("desiredIdentifier").option("disabled", false);
            this.addForm.instance.getEditor("desiredTitle").option("disabled",false);
            this.addForm.instance.getButton("submit").option("disabled",false);
        }

        
    }

    render() {

        const uploadHeaders = {
            'Authorization': 'bearer ' + this.props.user.access_token
        }

        let changingFormData = {
            changeReason: null,
            additional: '',
            fileName: null,
            documentId: null,
            externalApproval: this.props.rowData.externalApproval,
            owners: this.props.rowData.ownerIds,
            primary: this.props.rowData.primary,
        }

        let changingFormConfig = {
            items: [
                {
                    colspan: 1,
                    editorType: "dxTagBox",
                    dataField: "owners",
                    editorOptions: {
                        dataSource: getOwnersDataSource(this.props.user.access_token),
                        valueExpr:"id",
                        displayExpr: "roleHolder",
                        searchExpr: "roleHolder",
                        searchEnabled: true,
                        readOnly: true
                        
                    },
                    label : { text: "Owners"},
                   
                },
                {
                    colspan: 1,
                    dataField: "primary",
                    editorType: "dxSelectBox",
                    editorOptions: {
                        dataSource: getOwnersDataSource(this.props.user.access_token),
                        valueExpr:"id",
                        displayExpr: "roleHolder",
                        searchExpr: "roleHolder",
                        searchEnabled: true,
                        readOnly: true
                    },
                
                },
                {
                    colSpan: 2,
                  
                    dataField: "changeReason",
                    editorType: "dxTextArea",
                    editorOptions: {
                        height:"100px",
                        placeholder: "Please enter a brief summary of the reason for the update here...", 
                    },
                    label : { text: "Reason for Change"},
                    
                    validationRules: [{
                        type: "required",
                        message: "Change Reason Is Required"
                    },
                    {
                        type: "stringLength",
                        max: 4096,
                        message: "Change Reason cannot exceed 4096 Characters"      
                    }]
                },
                {
                    colspan: 1,
                    dataField: "readAndSign",
                    editorType: "dxTagBox",
                    editorOptions: {
                        dataSource: getGroupsDataSource(this.props.user.access_token),
                        valueExpr:"id",
                        displayExpr: "name",
                        searchExpr: "name",
                        searchEnabled: true,
                        
                    },
                    label : { text: "Select Read and Sign Groups - If applicable"},
                },
                {
                    colspan: 1,
                    dataField: "externalApproval",
                    editorType: "dxCheckBox",
                    label : { text: "Subject to NAA approval?"},
                    editorOptions: {
                        readOnly: true
                    }
                    

                },  
                {
                    colSpan: 2,
                  
                    dataField: "additional",
                    editorType: "dxTextArea",
                    editorOptions: {
                        height:"100px",
                        placeholder: "Please enter any additional requests or information here...", 
                    },
                    label : { text: "Additional Requests"},
                    validationRules: [{
                        type: "stringLength",
                        max: 4096,
                        message: "Additional text cannot exceed 4096 Characters"      
                    }]
                },
                {
                    itemType: "button",
                    horizontalAlignment: "right",
                    colSpan: 2,
                    buttonOptions: {
                        text: "Submit",
                        type: "success",
                        useSubmitBehavior: false,
                        onClick: this.submitChangeRequest
                    }
                }

            ]
        };
        
        let addFormData = {
            desiredSection: null,
            changeReason: null,
            additional: '',
            fileName: null,
            desiredEntity: null,
            naaApproval: false
  
           
        }

        let addFormConfig = {
            items: [
                {
                    colSpan: 1,  
                    dataField: "desiredEntity",
                    editorType: "dxSelectBox",
                    editorOptions: {
                        dataSource: getEntitiesDataSource(this.props.user.access_token),
                        valueExpr: "id",
                        searchExpr: "shortDescription",
                        displayExpr: "shortDescription",
                        searchEnabled: true,
                        height: "50%",
                        onSelectionChanged: this.entitySelection
                    },
                    label : { text: "Desired Doc Entity"},
                    validationRules: [{
                        type: "required",
                        message: "Doc Entity is required"
                    }]
                },
                {
                    colSpan: 1,  
                    dataField: "desiredIdentifier",
                    editorType: "dxTextBox",
                    editorOptions: {
                        disabled: true
                    },
                    label : { text: "Desired Identifier"},
                    validationRules: [{
                        type: "required",
                        message: "Identifier is required"
                    },
                    {
                        type: "stringLength",
                        max: 50,
                        message: "Identifier cannot exceed 50 Characters"      
                    },
                    {
                        type: 'custom',
                        validationCallback:  this.validateIdentifier,
                        message: 'Identifier in use'
                    }]
                },
                {
                    colSpan: 1,  
                    dataField: "desiredTitle",
                    editorType: "dxTextBox",
                    editorOptions: {
                        disabled: true
                    },
                    label : { text: "Desired Title"},
                    validationRules: [{
                        type: "required",
                        message: "Title is required"
                    },
                    {
                        type: "stringLength",
                        max: 255,
                        message: "Title cannot exceed 255 Characters"        
                    }]
                },
                {
                    colSpan: 1,  
                    dataField: "desiredSection",
                    editorType: "dxSelectBox",
                    editorOptions: {
                        dataSource: getSectionsDataSource(this.props.user.access_token),
                        valueExpr: "id",
                        searchExpr: "fullTitle",
                        displayExpr: "fullTitle",
                        searchEnabled: true,
                        height: "50%"
                    },
                    label : { text: "Desired Section"},
                    validationRules: [{
                        type: "required",
                        message: "Section is required"
                    }]
                },
              
                {
                    colspan: 1,
                    dataField: "owners",
                    editorType: "dxTagBox",
                    editorOptions: {
                        dataSource: getOwnersDataSource(this.props.user.access_token),
                        valueExpr:"id",
                        displayExpr: "roleHolder",
                        searchExpr: "roleHolder",
                        searchEnabled: true,
                        
                    },
                    label : { text: "Owners"},
                    validationRules: [{
                        type: "required",
                        message: "Owners are required"
                    }]
                },
                {
                    colspan: 1,
                    dataField: "primary",
                    editorType: "dxSelectBox",
                    editorOptions: {
                        dataSource: getOwnersDataSource(this.props.user.access_token),
                        valueExpr:"id",
                        displayExpr: "roleHolder",
                        searchExpr: "roleHolder",
                        searchEnabled: true,
                    },
                    label : { text: "Desired Primary Owner (if known)"},
                    validationRules: [{
                        type: "required",
                        message: "Primary Owner is required"
                    }]
                },
                {
                    colspan: 1,
                    dataField: "readAndSign",
                    editorType: "dxTagBox",
                    editorOptions: {
                        dataSource: getGroupsDataSource(this.props.user.access_token),
                        valueExpr:"id",
                        displayExpr: "name",
                        searchExpr: "name",
                        searchEnabled: true,
                        
                    },
                    label : { text: "Select Read and Sign Groups - If applicable"},
                },
                {
                    colspan: 1,
                    dataField: "naaApproval",
                    editorType: "dxCheckBox",
                    label : { text: "Subject to NAA approval?"},

                },  
                {
                    colSpan: 2,  
                    dataField: "changeReason",
                    editorType: "dxTextArea",
                    editorOptions: {
                        height:"100px",
                        placeholder: "Please enter a brief summary of the reason for the new document here...", 
                    },
                    label : { text: "Reason for Addition"},
                    validationRules: [{
                        type: "required",
                        message: "Addition Reason is required"
                    },
                    {
                        type: "stringLength",
                        max: 4096,
                        message: "Change Reason cannot exceed 4096 Characters"      
                    }]
                },
                {
                    colSpan: 2,
                  
                    dataField: "additional",
                    editorType: "dxTextArea",
                    editorOptions: {
                        height:"100px",
                        placeholder: "Please enter any additional requests or information here...",  
                    },
                    label : { text: "Additional Requests"},
                    validationRules: [
                    {
                        type: "stringLength",
                        max: 4096,
                        message: "Additional text cannot exceed 4096 Characters"      
                    }]
                },
                {
                    itemType: "button",
                    horizontalAlignment: "right",
                    colSpan: 2,
                    name: "submit",
                    buttonOptions: {  
                        text: "Submit",
                        type: "success",
                        disabled: true,
                        useSubmitBehavior: false,
                        onClick: this.submitAddChangeRequest
                    }
                }


            ]
        };

        let deleteFormData = {
            changeReason: null,
            documentId: null,
            owners: this.props.rowData.ownerIds,
            primary: this.props.rowData.primary,
            externalApproval: this.props.rowData.externalApproval,
           
        }

        let deleteFormConfig = {
            items: [
                {
                    colspan: 1,
                    editorType: "dxTagBox",
                    dataField: "owners",
                    editorOptions: {
                        dataSource: getOwnersDataSource(this.props.user.access_token),
                        valueExpr:"id",
                        displayExpr: "roleHolder",
                        searchExpr: "roleHolder",
                        searchEnabled: true,
                        readOnly: true
                        
                    },
                    label : { text: "Owners"},
                   
                },
               
                {
                    colspan: 1,
                    dataField: "primary",
                    editorType: "dxSelectBox",
                    editorOptions: {
                        dataSource: getOwnersDataSource(this.props.user.access_token),
                        valueExpr:"id",
                        displayExpr: "roleHolder",
                        searchExpr: "roleHolder",
                        searchEnabled: true,
                        readOnly: true
                    },
                
                },
                {
                    colspan: 1,
                    dataField: "externalApproval",
                    editorType: "dxCheckBox",
                    label : { text: "Subject to NAA approval?"},
                    editorOptions: {
                        readOnly: true
                    }
                }, 
                {
                    colspan: 1,
                    itemType: "empty"
                },
                 {
                    dataField: 'changeReason',
                    colSpan: 2,
                    editorType: "dxTextArea",
                    editorOptions: {
                        height:"200px",
                        placeholder: "Please enter a brief summary of the reason for the update here...", 
                    },
                    label : { text: "Reason for deletion"},
                    validationRules: [{
                        type: "required",
                        message: "Reason for deletion is required"
                    },
                    {
                        type: "stringLength",
                        max: 4096,
                        message: "Change Reason cannot exceed 4096 Characters"      
                    }]
                },
               
                {
                    itemType: "button",
                    horizontalAlignment: "right",
                    colSpan: 2,
                    buttonOptions: {
                        text: "Submit",
                        type: "success",
                        useSubmitBehavior: false,
                        onClick: this.submitDeleteRequest
                    }
                }
            ]
        };
      

        let form = null;
        if(this.props.showingDeleteForm === true) {
            form = 
            <Holder>
            <Form
             height="100%"
             colCount={2}
             formData={deleteFormData} 
             items={deleteFormConfig.items}
             ref={(form) => {this.deleteForm = form}}>
             </Form>   
             </Holder>
        }
        
        if(this.props.showingAddForm === true) {
            form = 

          <Holder>
            <FileUploader multiple={false} uploadMode={'instantly'} uploadHeaders={uploadHeaders}
            uploadUrl={ apis.MANUALAPI + 'api/changecontrol/upload/' } onUploaded={this.fileAddUploaded} ref={(afu) => {this.addUploader = afu}}  /> 
            <Form
            height="100%"
            colCount={2}
            formData={addFormData}
            items={addFormConfig.items}
            ref={(form) => {this.addForm = form}} />
         </Holder>
          }

      
        if(this.props.showingUpdateForm === true) {
            form = 
            <Holder>
            <FileUploader multiple={false} uploadMode={'instantly'} uploadHeaders={uploadHeaders}
            uploadUrl={ apis.MANUALAPI + 'api/changecontrol/upload/'}  onUploaded={this.fileUploaded} ref={(cfu) => {this.changeUploader = cfu}} /> 
            <Form
            height="100%"
            colCount={2}
            formData={changingFormData}
            items={changingFormConfig.items}
            ref={(form) => {this.changeForm = form}} />
            </Holder>
        }

        if(this.props.modeSelected === false) {
            form = <ChangeModeSelector checkOutStatus={this.state.checkoutData} checkOutClicked={this.checkOutClickedHandler} rowData={this.props.rowData} addClicked={this.addClickHandler} deleteClicked={this.deleteClickHandler} updateClicked={this.updateClickHandler} />
        }
        
        
        return(
            <div className="ChangeForm">
                {form}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.oidc.user,
        showingAddForm: state.changeControl.showingAddForm,
        showingDeleteForm: state.changeControl.showingDeleteForm,
        showingUpdateForm: state.changeControl.showingUpdateForm,
        modeSelected: state.changeControl.modeSelected
    };
}

const mapDispatchToProps = dispatch => {
    return {
       onShowingAddForm: () => dispatch(actions.showAddForm()),
       onHidingAddForm: () => dispatch(actions.hideAddForm()),
       onShowingUpdateForm: () => dispatch(actions.showUpdateForm()),
       onHidingUpdateForm: () => dispatch(actions.hideUpdateForm()),
       onShowingDeleteForm: () => dispatch(actions.showDeleteForm()),
       onHidingDeleteForm: () => dispatch(actions.hideDeleteForm()),
       onHidingChangePopup: () => dispatch(actions.hideChangePopup())
    };
  }


export default connect(mapStateToProps,mapDispatchToProps)(ChangeForm);