import React, {PureComponent} from 'react';
import Holder from '../../hoc/holder';
import LinkCell from '../../components/LinkCell/LinkCell';
import ChangeRequestPopup from '../../components/ChangeRequestPopup/ChangeRequestPopup';
import DataGrid, {
    Column,
    GroupPanel,
    FilterPanel,
    FilterRow,
    ColumnChooser,
    Scrolling,
    Pager,
    Paging
} from 'devextreme-react/ui/data-grid';

import ReviewPopup from '../../components/ReviewPopup/ReviewPopup';
import { Template } from 'devextreme-react/core/template';

import { createStore } from 'devextreme-aspnet-data-nojquery';
import { connect } from "react-redux";
import * as apis from '../../utils/apis';
import * as actions from '../../store/actions/actions';


const getHeight = () => {
    return (window.innerHeight - 120)
}

const getReviewDataSource = (token) => {
  return createStore({
    key: 'id',
    loadUrl: apis.MANUALAPI + 'api/clientreview/',
    onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers =  {'Authorization': "bearer " + token} 
      }
    }
  )
}

class ReviewGrid extends PureComponent {

    state = {
        popupVisible: false,
        rowData: {
            companyShortDescription: null,
            daysRemaining: null,
            id: null,
            identifier: null,
            issueDate: null,
            issueNumber: null,
            lastReviewed: null,
            reviewThreshold: 12,
            sectionEntityShortDescription: null,
            sectionTitle: null,
            title: null,
        }
  
    }

      resize = () => {
        this.grid.instance.option('height',getHeight());
      }
    
      componentDidMount() {
        window.addEventListener('resize', this.resize)
      }
      
      componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
      }

      clickHandler = (e) => {
        e.event.preventDefault();
        this.props.onShowingReviewPopup(e.row.data);
      }
      hidingPopupHandler = () => {
        this.props.onHidingReviewPopup();
       
      }

      reviewCompletedHandler = () => {
        let rowData = { ...this.state.rowData }
        rowData = null
          this.setState({ popupVisible : false, rowdata : rowData })

          // trigger reload of data in grid here.
          this.grid.instance.getDataSource().reload();
      }

      hidingChangePopupHandler = () => {
      this.props.onHidingChangePopup();
      this.props.onResetingChangeForm();
    }

    changeRequestClickHandler = (rowData) => {
      this.props.onShowingChangePopup(rowData);
    }

    test =() => {
      alert("Test");
    }
      render() {
        let saveKey = 'clReview';
        if(this.props.pagingEnabled === true) {
          saveKey = 'clReviewPaged';
        }  


       // let popup =  

         let changeRequestPopup =   <ChangeRequestPopup
         popupVisible={true}
         rowData={this.props.rowData}
         closeOnOutsideClick={true}
         hidingPopupHandler={this.hidingChangePopupHandler}
         >
         </ChangeRequestPopup>

          if(this.props.requestingChange === false)
          {
              changeRequestPopup = null;
          }

          return(
     <Holder>
          <DataGrid 
            dataSource={getReviewDataSource(this.props.user.access_token)}  
            noDataText="You have no reviews outstanding."
            rowAlternationEnabled={true}  
            showBorders={true}
            showRowLines={true}
            showColumnLines={true}  
            columnHidingEnabled={false}
            allowColumnReordering={true}
            allowColumnResizing={true}
            stateStoring={{ enabled: 'true',type: 'localStorage', storageKey: saveKey, savingTimeout:100 }}
            height={getHeight}
            width="100%" ref={(gv) => {this.grid = gv}}
         >
         {this.props.pagingEnabled ? 
         <Paging defaultPageSize={10} enabled={true} /> : <Scrolling mode="virtual" />
         }

        <Pager
        showPageSizeSelector={true}
        allowedPageSizes={[10, 20]}
        showInfo={true} />
        <GroupPanel visible={true} />
        <FilterRow visible={true} />
        <FilterPanel visible ={true} />
        <ColumnChooser enabled={true} />
   

        <Column dataField={'identifier'} cellTemplate={'linkCellTemplate'} visible={true} dataType='string' />
        <Column dataField={'title'} visible={true} dataType='string' />
        <Column dataField={'daysRemaining'} visible={true} dataType='number' />
        <Column dataField={'lastReviewed'} dataType="date" format="dd/MM/y" visible={true} />
        <Column dataField={'issueNumber'} visible={false} dataType='number' />
        <Column dataField={'sectionTitle'} visible={false} dataType='string' />
        <Column dataField={'companyShortDescription'} caption='Entity' visible={false} dataType='string' />
        <Column dataField={'sectionEntityShortDescription'} caption='Section Entity' visible={false} dataType='string' />
        <Column dataField={'issueDate'} dataType="date" format="dd/MM/y" visible={false} />
        <Column dataField={'externalApproval'} caption="NAA Approval" visible={false} dataType='boolean'/>
        <Column dataField={'revisionDate'} format="dd/MM/y" dataType="date" visible={false} />
        <Column dataField={'revisionNumber'} dataType="number" visible={false} />
        <Column type={'buttons'} width={75} allowReordering={false} showInColumnChooser={false} allowResizing={false}
          buttons={[ {
            text: 'Review',
            hint: 'Review',
            icon: 'ion ion-md-thumbs-up',
            onClick: this.clickHandler
          },
         
          ]} />
         <Template name={'linkCellTemplate'} render={LinkCell} />
        
        </DataGrid>
        <ReviewPopup
                      popupVisible={true}
                      closeOnOutsideClick={true}
                      hidingPopupHandler={this.hidingPopupHandler}
                      reviewCompleted={this.reviewCompletedHandler}
                      >
                     </ReviewPopup>
                   {changeRequestPopup}
        </Holder>
       
       
        );
      }

}

const mapStateToProps = (state) => {
  return {
      user: state.oidc.user,
   //   popupVisible: state.document.reviewing,
      requestingChange: state.document.requestingChange,
      pagingEnabled: state.prefs.pagingEnabled
  };
}

const mapDispatchToProps = dispatch => {
  return {
      onShowingReviewPopup: (rowData) => dispatch(actions.showReviewPopup(rowData)),
      onHidingReviewPopup: () => dispatch(actions.hideReviewPopup()),
      onShowingChangePopup: (rowData) => dispatch(actions.showChangePopup(rowData)),
      onHidingChangePopup: () => dispatch(actions.hideChangePopup()),
      onResetingChangeForm: () => dispatch(actions.resetChangeForm())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewGrid);