import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import LoginPage from "../../containers/LoginPage/LoginPage";
import LandingPage from "../../containers/LandingPage/LandingPage";

const MainPage = (props) => {
    const { user } = props;
    return !user || user.expired ? <LoginPage /> : <LandingPage />;
}

const mapStateToProps = (state) => {
    return {
        user: state.oidc.user
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainPage));