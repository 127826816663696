import React, {PureComponent} from 'react';
import Holder from '../../hoc/holder';
import AddFavouritePopup from '../../components/AddFavouritePopup/AddFavouritePopup';
import HistoryPopup from '../../components/HistoryPopup/HistoryPopup';
import OwnerPopup from '../../components/OwnerPopup/OwnerPopup';
import ChangeRequestPopup from '../../components/ChangeRequestPopup/ChangeRequestPopup';
import { connect } from "react-redux";

import * as actions from '../../store/actions/actions';
import UpdateGridComponent from './UpdateGridComponent';

class UpdateGrid extends PureComponent {     
     
      favouriteClickHandler = (e) => {
        e.event.preventDefault();
        this.props.onShowingFavouritePopup(e.row.data);
      }

      historyClickHandler = (e) => {
        e.event.preventDefault();
        this.props.onShowingHistoryPopup(e.row.data);
      }

      ownerClickHandler = (e) => {
        e.event.preventDefault();
        this.props.onShowingOwnerPopup(e.row.data);
      }

      changeRequestClickHandler = (e) => {
        e.event.preventDefault();
        this.props.onShowingChangePopup(e.row.data);
      }


      favouriteAddedHandler = () => {  
          this.gridview.instance.getDataSource().reload();
      }

      hidingChangePopupHandler = () => {
        this.props.onHidingChangePopup();
        this.props.onResetingChangeForm();
      }

      hidingFavouritePopupHandler = () => {
        this.props.onHidingFavouritePopup();
      }

      hidingHistoryPopupHandler = () => {
        this.props.onHidingHistoryPopup();
      }

      hidingOwnerPopupHandler = () => {
        this.props.onHidingOwnerPopup();
      }

      render() {

        let favouritePopup =  <AddFavouritePopup
        popupVisible={this.props.addingFavourite}
        closeOnOutsideClick={true}
        hidingPopupHandler={this.hidingFavouritePopupHandler}
        favouriteAdded={this.favouriteAddedHandler}
        >
       </AddFavouritePopup>

        if(this.props.addingFavourite === false)
        {
            favouritePopup = null;
        }

        let ownerPopup = <OwnerPopup
        popupVisible={this.props.viewingOwners}
        closeOnOutsideClick={true}
        hidingPopupHandler={this.hidingOwnerPopupHandler}
        token={this.props.user.access_token}
        >
          </OwnerPopup>


          if(this.props.viewingOwners === false)
          {
              ownerPopup = null;
          }

          let historyPopup = <HistoryPopup
          popupVisible={this.props.viewingHistory}
          closeOnOutsideClick={true}
          hidingPopupHandler={this.hidingHistoryPopupHandler}
          token = {this.props.user.access_token}
          >
          </HistoryPopup>

          if(this.props.viewingHistory === false)
          {
              historyPopup = null;
          }

          let changeRequestPopup = <ChangeRequestPopup
          popupVisible={this.props.requestingChange}
          closeOnOutsideClick={true}
          hidingPopupHandler={this.hidingChangePopupHandler}
          >
          </ChangeRequestPopup>

          if(this.props.requestingChange === false)
          {
              changeRequestPopup = null;
          }

          return(
          <Holder>
            <UpdateGridComponent favouriteClickHandler={this.favouriteClickHandler}
              historyClickHandler={this.historyClickHandler}
              changeRequestClickHandler={this.changeRequestClickHandler}
              ownerClickHandler={this.ownerClickHandler} />
        { changeRequestPopup }
        { historyPopup }
        { favouritePopup }
        { ownerPopup }
        </Holder>);
      }

}

const mapStateToProps = (state) => {
  return {
      user: state.oidc.user,
      addingFavourite: state.document.addingFavourite,
      requestingChange: state.document.requestingChange,
      viewingHistory: state.document.viewingHistory,
      viewingOwners: state.document.viewingOwners
  };
}

const mapDispatchToProps = dispatch => {
  return {
      onShowingFavouritePopup: (rowData) => dispatch(actions.showFavouritePopup(rowData)),
      onHidingFavouritePopup: () => dispatch(actions.hideFavouritePopup()),
      onShowingChangePopup: (rowData) => dispatch(actions.showChangePopup(rowData)),
      onHidingChangePopup: () => dispatch(actions.hideChangePopup()),
      onShowingHistoryPopup: (rowData) => dispatch(actions.showHistoryPopup(rowData)),
      onHidingHistoryPopup: () => dispatch(actions.hideHistoryPopup()),
      onShowingOwnerPopup: (rowData) => dispatch(actions.showOwnerPopup(rowData)),
      onHidingOwnerPopup: () => dispatch(actions.hideOwnerPopup()),
      onResetingChangeForm: () => dispatch(actions.resetChangeForm())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateGrid);
