import React from 'react';
import { Button } from 'devextreme-react';
import Holder from '../../../hoc/holder';
import { connect } from "react-redux";

const changeModeSelector = (props) => {
  console.log("Changemode", props);

  let checkOutButton = null;
  let changeButtons = null;

  if(props.checkOutStatus !== null) {

    if(props.checkOutStatus.checkedOut === false) {
    changeButtons = <Holder>{props.rowData.externalApproval ? <p>*** This document is subject to NAA Approval ***</p> : null }
    <Button
    text={'Submit Update Request'} width="100%"
      type={'default'}
      onClick={props.updateClicked}
    />
   
    <Button
    text={'Submit Document Addition Request'} width="100%"
    type={'default'}
    onClick={props.addClicked}
    />
     <Button
    text={'Submit Delete Request'} width="100%"
    type={'danger'}
    onClick={props.deleteClicked}
    />
    </Holder>
    }

    if(props.checkOutStatus.checkedOut === true && props.checkOutStatus.changeInProgress === false && props.checkOutStatus.checkOutBy === props.user.profile.sub) {
      changeButtons = <Holder>{props.rowData.externalApproval ? <p>*** This document is subject to NAA Approval ***</p> : null }
      <Button
      text={'Submit Update Request'} width="100%"
        type={'default'}
        onClick={props.updateClicked}
      />
      </Holder>
      }



    if(props.checkOutStatus.checkedOut !== null && props.rowData.editableCopy === true) {

      if(props.checkOutStatus.checkedOut === false) {
        checkOutButton = <Button 
        text = {'Checkout Editable Copy - Lock the document for change'} width="100%"
        type={'normal'}
        onClick={() => props.checkOutClicked(props.rowData.id)} />
      }
  
        if(props.checkOutStatus.checkedOut === true && props.checkOutStatus.changeInProgress === false) {
          checkOutButton =  <Button 
          text = {'Document Checked out by ' + props.checkOutStatus.checkedOutName} width="100%"
          type={'normal'} disabled />
        }

        if(props.checkOutStatus.checkedOut === true && props.checkOutStatus.changeInProgress === true) {
          checkOutButton =  <Button 
          text = {'Change submitted by ' + props.checkOutStatus.checkedOutName} width="100%"
          type={'normal'} disabled />
        }
  
        if(props.checkOutStatus.checkedOut === true) {
          if(props.checkOutStatus.checkOutBy === props.user.profile.sub && props.checkOutStatus.changeInProgress === true) {
            checkOutButton = <Button 
            text = {'You have a change request in progress.'} width="100%"
            type={'normal'}
             />
        }
        if(props.checkOutStatus.checkOutBy === props.user.profile.sub && props.checkOutStatus.changeInProgress === false) {
          checkOutButton = <Button 
          text = {'Editable copy checked out, redownload'} width="100%"
          type={'normal'}
          onClick={() => props.checkOutClicked(props.rowData.id)}
           />
      }
        
      }
    }
    else if(props.checkOutStatus.checkedOut === true && props.rowData.editableCopy === false) {

      if(props.checkOutStatus.checkOutBy === props.user.profile.sub) {
        changeButtons = <Holder><Button 
        text = {'You have a change request in progress.'} width="100%"
        type={'normal'} disabled /></Holder>
      
      }
      else {
      changeButtons = <Holder><Button 
      text = {'Change submitted by ' + props.checkOutStatus.checkedOutName} width="100%"
      type={'normal'} disabled /></Holder>
      }
    }
  } 

    return (
    <div>
        {changeButtons}
        {props.rowData.editableCopy ? checkOutButton : null } 
    </div>);

}
   
const mapStateToProps = (state) => {
  return {
      user: state.oidc.user,
  };
}


export default connect(mapStateToProps)(changeModeSelector);