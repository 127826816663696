import React from "react";
import userManager from '../../utils/userManager';

class LogoutPage extends React.Component {

   

    render() {
            
            userManager.signoutRedirect();
            return null;

    }
}



export default LogoutPage;