import React, {PureComponent} from 'react';
import {Popup, ScrollView, Form} from 'devextreme-react';
import { connect } from "react-redux";
import {animOff} from '../../utils/appConfig';

class CancelPopup extends PureComponent {


    
       
    render() {
        let changeFormConfig = {
            items: [
                {
                    colSpan: 1,  
                    dataField: "documentIdentifier",
                    editorType: "dxTextBox",
                    disabled: true,
                    editorOptions: {
                        
                    },
                    label : { text: "Identifier"},
                    
                },
                {
                    colSpan: 1,  
                    dataField: "documentTitle",
                    editorType: "dxTextBox",
                    disabled: true,
                    editorOptions: {
                        
                    },
                    label : { text: "Title"},
                   
                },
                 {
                    dataField: 'changeReason',
                    colSpan: 2,
                    editorType: "dxTextArea",
                    disabled: true,
                    editorOptions: {
                        height:"200px",
                    },
                    label : { text: "Change Reason"},
                   
                },
                {
                    colSpan: 2,  
                    dataField: "originator",
                    editorType: "dxTextBox",
                    disabled: true,
                    editorOptions: {
                        
                    },
                    label : { text: "Originator"},
                    
                },
               
                {
                    itemType: "button",
                    horizontalAlignment: "right",
                    colSpan: 2,
                    buttonOptions: {
                        text: "CANCEL CHANGE",
                        type: "danger",
                        useSubmitBehavior: false,
                        onClick: () => this.props.cancelClicked(this.props.rowData.id)
                    }
                }
            ]
        };




        return <Popup 
        visible={this.props.popupVisible}
        closeOnOutsideClick={this.props.closeOnOutsideClick}
        onHidden={this.props.hidingCancelPopupHandler}
        animation={animOff} title="Cancel Change Request" showCloseButton={true}>
        <ScrollView>
        <Form visible={true}
            height="100%"
            colCount={2}
            formData={this.props.rowData}
            items={changeFormConfig.items}
            ref={(form) => {this.changeForm = form}}>
            </Form> 
        
        </ScrollView>

       </Popup>
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.oidc.user,
        rowData: state.changeControl.rowData
    };
}


export default connect(mapStateToProps)(CancelPopup);

