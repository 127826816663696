import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { CallbackComponent } from "redux-oidc";
import userManager from "../../utils/userManager";
import * as actions from '../../store/actions/actions';


class CallbackPage extends React.Component {
    render() {
        // just add code to redirect to '/' in both cases
        return (
            <div>
                <CallbackComponent
                    userManager={userManager}
                    successCallback={(user) => {                       
                        this.props.onLoggedIn(user.profile.selectedentity);
                        this.props.history.push("/");
                    }
                    }
                    errorCallback={error => {
                        console.error(error);
                    }}
                >
                    <div>Redirecting...</div>
                </CallbackComponent>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoggedIn: (entityId) => dispatch(actions.changeEntity(entityId)),
    };
}


export default withRouter(connect(null,mapDispatchToProps)(CallbackPage));