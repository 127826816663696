import { createStore, applyMiddleware, compose } from "redux";
import { loadUser } from "redux-oidc";
import reducer from "./reducers";
import userManager from "../utils/userManager";
import thunk from 'redux-thunk';

const initialState={};

//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducer,initialState, applyMiddleware(thunk));
loadUser(store, userManager);
export default store;