import React, {Component} from 'react';
import axios from 'axios';
import {withRouter} from 'react-router-dom';
import SearchResult from '../../components/SearchResult/SearchResult';
import AddFavouritePopup from '../../components/AddFavouritePopup/AddFavouritePopup';
import OwnerPopup from '../../components/OwnerPopup/OwnerPopup';
import ChangeRequestPopup from '../../components/ChangeRequestPopup/ChangeRequestPopup';
import HistoryPopup from '../../components/HistoryPopup/HistoryPopup';
import { connect } from "react-redux";
import * as actions from '../../store/actions/actions';
import './SearchResults.css';
import {LoadPanel} from 'devextreme-react';


const getHeight = () => {
    return (window.innerHeight - 140)
}

class SearchResults extends Component {
    state ={
        results: null,
        searchPhrase: null,
     }

    componentDidMount() {
        window.addEventListener('resize', this.resize);


        const queryString = new URLSearchParams(this.props.location.search);
             
        const query = {};

         for(let param of queryString.entries()) {
            query[param[0]] = param[1];
        } 

       
        // fetch data from API here
        if(query.searchPhrase !== null) {
            axios.get('api/search/' + query.searchPhrase)
            .then(response => {
                this.setState({ results: response.data.data, searchPhrase: query.searchPhrase});
                this.props.onSearching(false);
            });
        }
        
    }

    componentDidUpdate() {
        const queryString = new URLSearchParams(this.props.location.search);
             
        const query = {};

         for(let param of queryString.entries()) {
            query[param[0]] = param[1];
        } 


          if(query.searchPhrase) {
            if(this.state.searchPhrase !== query.searchPhrase) {
                axios.get('api/search/' + query.searchPhrase)
                .then(response => {
                    this.setState({ results: response.data.data, searchPhrase: query.searchPhrase });
                    this.props.onSearching(false);
                });
            }
        }
 
    }

    componentWillUnmount() {
         window.removeEventListener('resize', this.resize);
    }

    resize = () => {
        this.setState({divHeight : window.innerHeight - 140})
    }

    clickHandler = (event,docId) => {
        event.preventDefault();
       
        
        let dlRequest = {
            id: docId,
            client: true
        }
       
        axios.post('api/downloadlink/getdownloadlink',dlRequest)
        .then(response => {
            window.open(response.data, '_blank');
        })
        .catch(error => {
            console.log(error)
        });

      
    }

    favouriteAddedHandler = () => {
   
    }

    favouriteClickHandler = (rowData) => {
        this.props.onShowingFavouritePopup(rowData)
    }

      historyClickHandler = (rowData) => {
        this.props.onShowingHistoryPopup(rowData);
    }

    ownerClickHandler = (rowData) => {
      this.props.onShowingOwnerPopup(rowData)  
    }

    changeRequestClickHandler = (rowData) => {
    this.props.onShowingChangePopup(rowData)
    }
   
    hidingFavouritePopupHandler = () => {
    this.props.onHidingFavouritePopup();
    }

    hidingChangePopupHandler = () => {
    this.props.onHidingChangePopup();
    this.props.onResetingChangeForm();
    }

    hidingHistoryPopupHandler = () => {
    this.props.onHidingHistoryPopup();
    }

    hidingOwnerPopupHandler = () => {
    this.props.onHidingOwnerPopup();
    }

    render() {
        let resultsToRender = null;

        let favouritePopup =  <AddFavouritePopup
        popupVisible={this.props.addingFavourite}
        closeOnOutsideClick={true}
        hidingPopupHandler={this.hidingFavouritePopupHandler}
        >
       </AddFavouritePopup>

        let ownerPopup = <OwnerPopup
        popupVisible={this.props.viewingOwners}
        hidingPopupHandler={this.hidingOwnerPopupHandler}
        token={this.props.user.access_token}
        >
        </OwnerPopup>

        let historyPopup = <HistoryPopup
        popupVisible={this.props.viewingHistory}
        closeOnOutsideClick={true}
        hidingPopupHandler={this.hidingHistoryPopupHandler}
        token = {this.props.user.access_token}
        >
        </HistoryPopup>

       

        let changeRequestPopup = <ChangeRequestPopup
        popupVisible={this.props.requestingChange}
        closeOnOutsideClick={true}
        hidingPopupHandler={this.hidingChangePopupHandler}
         >
        </ChangeRequestPopup>
        
        if(this.props.addingFavourite === false)
        {
            favouritePopup = null;
        }

        if(this.props.viewingOwners === false)
        {
            ownerPopup = null;
        }

        if(this.props.viewingHistory === false) {
            historyPopup = null;
        }

        if(this.props.requestingChange === false) {
            changeRequestPopup = null;
        }

        let loadPanel = <LoadPanel
        shadingColor={'rgba(0,0,0,0.4)'}
        visible={true}
        showIndicator={true}
        shading={true}
        showPane={true}
         />

         if(this.props.loading === false) {
             loadPanel = null;
         }

        if(this.state.results !== null && this.state.results.length !== 0)  
        {
            resultsToRender =  this.state.results.map(res => 
            (
                <SearchResult clicked={(event) => this.clickHandler(event,res.id)} key= {res.id} result={res} searchPhrase={this.state.searchPhrase.split(' ')} favouriteClicked={(res) => this.favouriteClickHandler(res)} historyClicked={(res) => this.historyClickHandler(res)}
                ownerClicked={(res) => this.ownerClickHandler(res)} changeClicked={(res) => this.changeRequestClickHandler(res)} />
            ))
        }
        else
        {
            if(this.props.loading === false) {
            resultsToRender = <p>No Matches Found</p> 
            } else
            {
                resultsToRender = null;
            }
        }

        return(
            <div className="SearchResultContainer" style={ { height: getHeight() } }>
            {loadPanel}
             {resultsToRender}
             {favouritePopup}
             {historyPopup}
             {ownerPopup}
             {changeRequestPopup}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.oidc.user,
        addingFavourite: state.document.addingFavourite,
        requestingChange: state.document.requestingChange,
        viewingHistory: state.document.viewingHistory,
        viewingOwners: state.document.viewingOwners,
        loading: state.search.loading
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onShowingFavouritePopup: (rowData) => dispatch(actions.showFavouritePopup(rowData)),
        onHidingFavouritePopup: () => dispatch(actions.hideFavouritePopup()),
        onShowingChangePopup: (rowData) => dispatch(actions.showChangePopup(rowData)),
        onHidingChangePopup: () => dispatch(actions.hideChangePopup()),
        onShowingHistoryPopup: (rowData) => dispatch(actions.showHistoryPopup(rowData)),
        onHidingHistoryPopup: () => dispatch(actions.hideHistoryPopup()),
        onShowingOwnerPopup: (rowData) => dispatch(actions.showOwnerPopup(rowData)),
        onHidingOwnerPopup: () => dispatch(actions.hideOwnerPopup()),
        onResetingChangeForm: () => dispatch(actions.resetChangeForm()),
        onSearching: (searching) => dispatch(actions.searching(searching)),
    };
  }

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(SearchResults));
