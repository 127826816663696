import React, {Component} from 'react';
import { Button } from 'devextreme-react';
import axios from 'axios';

class DraftDownloadButton extends Component {

    downloadDraft = () => {
      
        // let bodyFormData = new FormData();
        // bodyFormData.set('id',this.props.changeId);

        let bodyFormData = {
            id: this.props.changeId
        }

        axios.post('api/downloadlink/getdraftlink',bodyFormData)
        .then(response => {
            window.location.href = (response.data);
        })
        .catch(error => {
            console.log(error)
        });
    }

    render() {

        return <Button type='default' onClick={this.downloadDraft} visible={this.props.visible}>Download Draft</Button>
       
        
    }

}

export default DraftDownloadButton;




