import * as actionTypes from './actionTypes';
import axios from 'axios';
import notify from 'devextreme/ui/notify';

export const changeEntity = (entityId) => {
    return {
        type: actionTypes.CHANGE_ENTITY,
        val: entityId
    }

}

export const searching = (searching) => {
    return {
        type: actionTypes.SEARCHING,
        val: searching
    }
}

export const loadingSection = (loading) => {
    return {
        type: actionTypes.LOADINGSECTION,
        val: loading
    }
}

const addFavourite = (status) => {
    return {
        type: actionTypes.ADD_FAVOURITE,
        val: status
    }
}

export const actionChangeRequest = (status) => {
    return {
        type: actionTypes.ACTION_CHANGE_REQUEST,
        val: status
    }
}



const readAndSign = (status) => {
    return {
        type: actionTypes.READ_AND_SIGN,
        val: status
    }

}



export const readAndSignAsync = (docId) => {
    let readSignDoc = {
        docId: docId,
        
    }

    return dispatch => {
        axios.put('api/readandsign', readSignDoc)
        .then(response => {
           dispatch(readAndSign(response.status));
           dispatch(hideReadAndSignPopup());
           notify("Read & Sign Completed","success", 1500);
        })
        .catch(error => {
            console.log(error);
            dispatch(readAndSignFailed());
        });
    }
}

export const hideFavouritePopup = () => {
    return {
        type: actionTypes.HIDE_FAVOURITE_POPUP
    }
}

export const showReadAndSignPopup = (rowData) => {
    return {
        type: actionTypes.SHOW_READ_AND_SIGN_POPUP,
        rowData: rowData
    }
}

export const showCancelPopup = (rowData) => {
    return {
        type: actionTypes.SHOW_CANCEL_POPUP,
        rowData: rowData
    }
}

export const hideCancelPopup = () => {
    return {
        type: actionTypes.HIDE_CANCEL_POPUP,

    }
}

export const hideChangePopup = () => {
    return {
        type: actionTypes.HIDE_CHANGE_POPUP
    }
}

export const showChangePopup = (rowData) => {
    return {
        type: actionTypes.SHOW_CHANGE_POPUP,
        rowData: rowData
    }
}

export const hideHistoryPopup = () => {
    return {
        type: actionTypes.HIDE_HISTORY_POPUP
    }
}

export const showHistoryPopup = (rowData) => {
    return {
        type: actionTypes.SHOW_HISTORY_POPUP,
        rowData: rowData
    }
}

export const hideReadAndSignPopup = () => {
    return {
        type: actionTypes.HIDE_READ_AND_SIGN_POPUP
    }
}

export const showOwnerPopup = (rowData) => {
    return {
        type: actionTypes.SHOW_OWNER_POPUP,
        rowData: rowData
    }
}

export const hideOwnerPopup = () => {
    return {
        type: actionTypes.HIDE_OWNER_POPUP
    }
}

export const showReviewPopup = (rowData) => {
    return {
        type: actionTypes.SHOW_REVIEW_POPUP,
        rowData: rowData
    }
}

export const hideReviewPopup = () => {
    return {
        type: actionTypes.HIDE_REVIEW_POPUP
    }
}

export const showApprovalPopup = (rowData) => {
    return {
        type: actionTypes.SHOW_APPROVAL_POPUP,
        rowData: rowData

    }
}

export const showViewChangePopup = (rowData) => {
    return {
        type: actionTypes.SHOW_VIEW_CHANGE_POPUP,
        rowData: rowData
    }
}

export const hideViewChangePopup = () => {
    return {
        type: actionTypes.HIDE_VIEW_CHANGE_POPUP
    }
}

export const hideApprovalPopup = () => {
    return {
        type: actionTypes.HIDE_APPROVAL_POPUP
    }
}



export const showFavouritePopup = (rowData) => {
    return {
        type: actionTypes.SHOW_FAVOURITE_POPUP,
        rowData: rowData
    }
}

export const selectHistory = (rowData) => {
    return {
        type: actionTypes.SELECT_HISTORY,
        rowData: rowData
    }
}

export const showRemoveFavouritePopup = (rowData) => {
    return {
        type: actionTypes.SHOW_REMOVE_FAVOURITE_POPUP,
        rowData: rowData
    }
}

export const hideRemoveFavouritePopup = () => {
    return {
        type: actionTypes.HIDE_REMOVE_FAVOURITE_POPUP
    }
}

export const addFavouriteAsync = (docId) => {
    
    let favouriteDoc = {
        docId: docId
    }

    return dispatch => {
        axios.post('api/favourites', favouriteDoc)
        .then(response => {
           dispatch(addFavourite(response.status));
           dispatch(hideFavouritePopup());
           notify("Favourite Added","success", 1500);
        })
        .catch(error => {
            console.log(error);
            dispatch(addFavouriteFailed());
        });
    }
}

export const reviewDocAsync = (docId, payload) => {
    let docReview = {
        id: docId,
        reviewText: payload.reviewText
    }

    return dispatch => {
        axios.post('api/clientreview', docReview)
        .then(response => {
            dispatch(reviewDoc(payload.daysRemaining));
            dispatch(hideReviewPopup());
            notify("Review Completed","success", 1500);
        })
        .catch(error => {
            console.log(error);
            dispatch(reviewDocFailed());
        });
    }
}

const reviewDoc = (daysRemaining) => {
    return {
        type: actionTypes.REVIEW_DOC,
        daysRemaining: daysRemaining
    }
}

export const removeFavouriteAsync = (docId) => {

    let favouriteDoc = {
        docId: docId
    }

    return dispatch => {
        axios.put('api/favourites', favouriteDoc)
        .then(response => {
           dispatch(removeFavourite(response.status));
           dispatch(hideRemoveFavouritePopup());
           notify("Favourite Removed","success", 1500);
        })
        .catch(error => {
            console.log(error);
            dispatch(removeFavouriteFailed());
        });
    }
}

const removeFavourite = (status) => {
    return {
        type: actionTypes.REMOVE_FAVOURITE,
        val: status
    }
}

export const removeFavouriteFailed = () => {
    return {
        type: actionTypes.REMOVE_FAVOURITE_FAILED
    }
}

export const addFavouriteFailed = () => {
    return {
        type: actionTypes.ADD_FAVOURITE_FAILED
    }
}

export const readAndSignFailed = () => {
    return {
        type: actionTypes.READ_AND_SIGN_FAILED
    }
}

export const reviewDocFailed = () => {
    return {
        type: actionTypes.REVIEW_DOC_FAILED
    }
}

export const setBadgeCounts = (badgeCounts) => {
    return {
        type: actionTypes.SET_BADGE_COUNTS,
        badgeCounts: badgeCounts
    }
}

export const fetchBadgeCountsFailed = () => {
    return {
        type: actionTypes.FETCH_BADGE_COUNTS_FAILED
    }
}

export const initBadgeCountsAsync = () => {
    return dispatch => {
        axios.get('/api/badgecounts')
        .then(response => {
            dispatch(setBadgeCounts(response.data));
        })
        .catch(error => {
            dispatch(fetchBadgeCountsFailed());
        })
    }
}

export const showAddForm = () => {
    return {
        type: actionTypes.SHOW_ADD_FORM
    }
}

export const showUpdateForm = () => {
    return {
        type: actionTypes.SHOW_UPDATE_FORM
    }
}

export const showDeleteForm = () => {
    return {
        type: actionTypes.SHOW_DELETE_FORM
    }
}

export const hideAddForm =() => {
    return {
        type: actionTypes.HIDE_ADD_FORM
    }
}

export const hideUpdateForm = () => {
    return {
        type: actionTypes.HIDE_UPDATE_FORM
    }
}

export const hideDeleteForm = () => {
    return {
        type: actionTypes.HIDE_DELETE_FORM
    }
}

export const resetChangeForm = () => {
    return {
        type: actionTypes.RESET_CHANGE_FORM
    }
}

export const pagingOn = () => {
    return {
        type: actionTypes.PAGINGON
    }
}

export const pagingOff = () => {
    return {
        type: actionTypes.PAGINGOFF
    }
}