import React from 'react';

import './SideDrawer.css';

const sideDrawer = (props) => {

    let cssclass = ['SideDrawer'];

    if(props.show === true)
    {
        cssclass.push('Open')
    }
    else
    {
        cssclass.push('Close')
    }

    return (
        <div className={cssclass.join(' ')}>
         {props.children}
        </div>
    );
}

export default sideDrawer;
