export const changeTypes = [
    { id: 0, text: "DELETE" },
    { id: 1, text: "UPDATE" },
    { id: 2, text: "ADD" }
];

export const statusTypes = [
    {id: 0, text: 'REJECTED'},
    {id: 1, text: 'ACCEPTED'},
    {id: 2, text: 'WAITING'},
    {id: 3, text: 'CANCELLED'},
    {id: 4, text: 'WAITING_QA'},
]