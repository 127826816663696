import React, {Component} from 'react';
import { connect } from "react-redux";
import notify from 'devextreme/ui/notify';
import { ScrollView, Form } from 'devextreme-react';
import axios from 'axios';

import './induction.css';

const getHeight = () => {
    return (window.innerHeight - 120)
}

class Induction extends Component {
    state  = {
        submitted: false,

        
    }

    resize = () => {
        this.scrollview.instance.option('height',getHeight());
    }
    
    componentDidMount() {
        window.addEventListener('resize', this.resize)
    }
      
    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    submitInduction = (params) => {
        var result = params.validationGroup.validate();
        if(result.isValid) {
            axios.post('api/induction',this.inductionForm.instance.option("formData") )
            .then(response => {
                  
                notify("Induction Questionaire Submitted","success", 1500);
                this.setState({ submitted: true});   
            })
            .catch(error => {
                console.log(error);
                notify("Induction Questionaire NOT Submitted","danger", 1500);
                this.setState({ submitted: false});  
            });
        }
    }

    render() {
        let questions = [
            'Q1: What is the procedure number for the change control process?',
            'Q2: Who can initiate a change to a document or process and how is it done?',
            'Q3: What is the procedure number for continuation training?',
            'Q4: How long are continuation training certificates valid for?',
            'Q5: How is Training delivered?',
            'Q6: What is the procedure number for aircraft husbandry?',
            'Q7: How is a removed component labelled?',
            'Q8: How should removed components be handled?',
            'Q9: Describe how work should be performed and areas should be maintained:',
            'Q10: What is the procedure number for the control of critical maintenance tasks?',
            'Q11: Who is responsible for raising independent or re-inspections?',
            'Q12: Where do the requirements for independent inspections come from?',
            'Q13: Who can carry out independent inspections?',
            'Q14: What is the Tool control procedure number?',
            'Q15: What are the fundamentals of BOSA tool control?',
            'Q16: What is the procedure number for aircraft release to service',
            'Q17: What is the procedure number for occurrence reporting?',
            'Q18: How do you submit a report?',
            'Q19: What is the procedure number that covers an application for or renewal of engineering authorisation?',
            'Q20: Which procedure covers maintenance handovers?',
            'Q21: Which documents are used for handovers?',
            'Q22: What should be used to back up a written handover?'
        ];


        let formData = {
            answers: [
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                
            ],
           
    
        }
    
        let formConfig = {
            items: [
                {
                    itemType: "group",
                    colSpan: 1,
                    caption: "Induction Questionaire",
                    
                    items: [
                        {
                            colSpan: 1,
                          
                            dataField: "answers[0]",
                            editorType: "dxTextBox",
                            label : { 
                                text: questions[0]
                            },
                            validationRules: [
                            {
                                type: "stringLength",
                                max: 1000,
                                message: "Answer cannot exceed 1000 Characters"      
                            }]
                        },
                        {
                            colSpan: 1,
                          
                            dataField: "answers[1]",
                            editorType: "dxTextBox",
                            label : { 
                                text: questions[1]
                            },
                            validationRules: [
                            {
                                type: "stringLength",
                                max: 1000,
                                message: "Answer cannot exceed 1000 Characters"      
                            }]
                        },
                        {
                            colSpan: 1,
                          
                            dataField: "answers[2]",
                            editorType: "dxTextBox",
                            label : { 
                                text: questions[2]
                            },
                            validationRules: [
                            {
                                type: "stringLength",
                                max: 1000,
                                message: "Answer cannot exceed 1000 Characters"      
                            }]
                        },
                        {
                            colSpan: 1,
                          
                            dataField: "answers[3]",
                            editorType: "dxTextBox",
                            label : { 
                                text: questions[3]
                            },
                            validationRules: [
                            {
                                type: "stringLength",
                                max: 1000,
                                message: "Answer cannot exceed 1000 Characters"      
                            }]
                        },
                        {
                            colSpan: 1,
                          
                            dataField: "answers[4]",
                            editorType: "dxTextBox",
                            label : { 
                                text: questions[4]
                            },
                            validationRules: [
                            {
                                type: "stringLength",
                                max: 1000,
                                message: "Answer cannot exceed 1000 Characters"      
                            }]
                        },
                        {
                            colSpan: 1,
                          
                            dataField: "answers[5]",
                            editorType: "dxTextBox",
                            label : { 
                                text: questions[5]
                            },
                            validationRules: [
                            {
                                type: "stringLength",
                                max: 1000,
                                message: "Answer cannot exceed 1000 Characters"      
                            }]
                        },
                        {
                            colSpan: 1,
                          
                            dataField: "answers[6]",
                            editorType: "dxTextBox",
                            label : { 
                                text: questions[6]
                            },
                            validationRules: [
                            {
                                type: "stringLength",
                                max: 1000,
                                message: "Answer cannot exceed 1000 Characters"      
                            }]
                        },
                        {
                            colSpan: 1,
                          
                            dataField: "answers[7]",
                            editorType: "dxTextBox",
                            label : { 
                                text: questions[7]
                            },
                            validationRules: [
                            {
                                type: "stringLength",
                                max: 1000,
                                message: "Answer cannot exceed 1000 Characters"      
                            }]
                        },
                        {
                            colSpan: 1,
                          
                            dataField: "answers[8]",
                            editorType: "dxTextBox",
                            label : { 
                                text: questions[8]
                            },
                            validationRules: [
                            {
                                type: "stringLength",
                                max: 1000,
                                message: "Answer cannot exceed 1000 Characters"      
                            }]
                        },
                        {
                            colSpan: 1,
                          
                            dataField: "answers[9]",
                            editorType: "dxTextBox",
                            label : { 
                                text: questions[9]
                            },
                            validationRules: [
                            {
                                type: "stringLength",
                                max: 1000,
                                message: "Answer cannot exceed 1000 Characters"      
                            }]
                        },
                        {
                            colSpan: 1,
                          
                            dataField: "answers[10]",
                            editorType: "dxTextBox",
                            label : { 
                                text: questions[10]
                            },
                            validationRules: [
                            {
                                type: "stringLength",
                                max: 1000,
                                message: "Answer cannot exceed 1000 Characters"      
                            }]
                        },
                        {
                            colSpan: 1,
                          
                            dataField: "answers[11]",
                            editorType: "dxTextBox",
                            label : { 
                                text: questions[11]
                            },
                            validationRules: [
                            {
                                type: "stringLength",
                                max: 1000,
                                message: "Answer cannot exceed 1000 Characters"      
                            }]
                        },
                        {
                            colSpan: 1,
                          
                            dataField: "answers[12]",
                            editorType: "dxTextBox",
                            label : { 
                                text: questions[12]
                            },
                            validationRules: [
                            {
                                type: "stringLength",
                                max: 1000,
                                message: "Answer cannot exceed 1000 Characters"      
                            }]
                        },
                        {
                            colSpan: 1,
                          
                            dataField: "answers[13]",
                            editorType: "dxTextBox",
                            label : { 
                                text: questions[13]
                            },
                            validationRules: [
                            {
                                type: "stringLength",
                                max: 1000,
                                message: "Answer cannot exceed 1000 Characters"      
                            }]
                        },
                        {
                            colSpan: 1,
                          
                            dataField: "answers[14]",
                            editorType: "dxTextBox",
                            label : { 
                                text: questions[14]
                            },
                            validationRules: [
                            {
                                type: "stringLength",
                                max: 1000,
                                message: "Answer cannot exceed 1000 Characters"      
                            }]
                        },
                        {
                            colSpan: 1,
                          
                            dataField: "answers[15]",
                            editorType: "dxTextBox",
                            label : { 
                                text: questions[15]
                            },
                            validationRules: [
                            {
                                type: "stringLength",
                                max: 1000,
                                message: "Answer cannot exceed 1000 Characters"      
                            }]
                        },
                        {
                            colSpan: 1,
                          
                            dataField: "answers[16]",
                            editorType: "dxTextBox",
                            label : { 
                                text: questions[16]
                            },
                            validationRules: [
                            {
                                type: "stringLength",
                                max: 1000,
                                message: "Answer cannot exceed 1000 Characters"      
                            }]
                        },
                        {
                            colSpan: 1,
                          
                            dataField: "answers[17]",
                            editorType: "dxTextBox",
                            label : { 
                                text: questions[17]
                            },
                            validationRules: [
                            {
                                type: "stringLength",
                                max: 1000,
                                message: "Answer cannot exceed 1000 Characters"      
                            }]
                        },
                        {
                            colSpan: 1,
                          
                            dataField: "answers[18]",
                            editorType: "dxTextBox",
                            label : { 
                                text: questions[18]
                            },
                            validationRules: [
                            {
                                type: "stringLength",
                                max: 1000,
                                message: "Answer cannot exceed 1000 Characters"      
                            }]
                        },
                        {
                            colSpan: 1,
                          
                            dataField: "answers[19]",
                            editorType: "dxTextBox",
                            label : { 
                                text: questions[19]
                            },
                            validationRules: [
                            {
                                type: "stringLength",
                                max: 1000,
                                message: "Answer cannot exceed 1000 Characters"      
                            }]
                        },
                        {
                            colSpan: 1,
                          
                            dataField: "answers[20]",
                            editorType: "dxTextBox",
                            label : { 
                                text: questions[20]
                            },
                            validationRules: [
                            {
                                type: "stringLength",
                                max: 1000,
                                message: "Answer cannot exceed 1000 Characters"      
                            }]
                        },
                        {
                            colSpan: 1,
                          
                            dataField: "answers[21]",
                            editorType: "dxTextBox",
                            label : { 
                                text: questions[21]
                            },
                            validationRules: [
                            {
                                type: "stringLength",
                                max: 1000,
                                message: "Answer cannot exceed 100 Characters"      
                            }]
                        },
                        {
                            itemType: "button",
                            horizontalAlignment: "center",
                            colSpan: 1,
                            buttonOptions: {
                                text: "Submit",
                                type: "success",
                                useSubmitBehavior: false,
                                onClick: this.submitInduction
                            }
                        },
                        {
                            itemType: "empty",
                            colSpan: 1,
                        }
                    ]
                }

                
            ]
            };

        
        let inductionForm = (
            <ScrollView height={getHeight} ref={(sv) => {this.scrollview = sv}}>
            <Form formData={formData} items={formConfig.items} ref={(form) => {this.inductionForm = form}} ></Form>
            </ScrollView>
        )

        if(this.state.submitted === true) {
            inductionForm = (
                <p>Thank you for your submitted Induction Questionaire, you will receive a copy of the answers provided by email.</p>
            )
        }
        return (
        <div id='induction'>
        
        {inductionForm}
        </div>)
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.oidc.user,
    };
}

export default connect(mapStateToProps,null)(Induction)