import React, {Component} from 'react';
import LinkCell from '../../components/LinkCell/LinkCell';
import Holder from '../../hoc/holder';
import ReadSignPopup from '../../components/ReadSignPopup/ReadSignPopup';

import { createStore } from 'devextreme-aspnet-data-nojquery';
import { connect } from "react-redux";
import * as apis from '../../utils/apis';
import * as actions from '../../store/actions/actions';

import DataGrid, {
    Column,
    GroupPanel,
    FilterPanel,
    FilterRow,
    ColumnChooser,
    Scrolling,
    Pager,
    Paging
} from 'devextreme-react/ui/data-grid';

import { Template } from 'devextreme-react/core/template';


const getHeight = () => {
    return (window.innerHeight - 120)
}

const getReadAndSignDataSource = (token) => {
  return createStore({
    key: 'id',
    loadUrl: apis.MANUALAPI + 'api/readandsign/',
    onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers =  {'Authorization': "bearer " + token} 
      }
    }
  )
}

class ReadAndSignGrid extends Component {

    state = {
        popupVisible: false,
     
    }


  showThumbIcon = (e) => {
     return !e.row.data.feedbackRequired;
  }

  showFeedbackIcon = (e) => {
    return e.row.data.feedbackRequired;
  }

      resize = () => {
        this.grid.instance.option('height',getHeight());
      }
    
      componentDidMount() {
        window.addEventListener('resize', this.resize)
        this.props.onInitBadgeCount();
      }
      
      componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
      }

      componentDidUpdate() {
          // we will be updating if the screen size changes, we're not interested in handling that.
          //console.log(this.props);
         
      }


      clickHandler = (e) => {
        e.event.preventDefault();
        this.props.onShowingReadAndSignPopup(e.row.data);
      }
      hidingPopupHandler = () => {
        this.props.onHidingReadAndSignPopup();
       
      }

      readSignCompletedHandler = () => {
          this.grid.instance.getDataSource().reload();
      }

      // componentWillUpdate() {
      //   return false;
      // }

      calculateReadTime(rowData) {
        if(rowData.wordCount > 1)
        {
        return Math.ceil(rowData.wordCount / 250);
        }
        return "N/A";
    }

      render() {
        let saveKey = 'clRandS';
        if(this.props.pagingEnabled === true) {
          saveKey = 'clRandSPaged';
        }  


      /*   let popup =  <ReadSignPopup
        popupVisible={this.props.popupVisible}
        closeOnOutsideClick={true}
        hidingPopupHandler={this.hidingPopupHandler}
        readSignCompleted={this.readSignCompletedHandler}
        >
       </ReadSignPopup> */

       
          return(
          <Holder>
          <DataGrid 
            dataSource={getReadAndSignDataSource(this.props.user.access_token)}  
            rowAlternationEnabled={true}  
            noDataText="You have no read and signs to action."
            showBorders={true}
            showRowLines={true}
            showColumnLines={true}  
            columnHidingEnabled={false}
            allowColumnReordering={true}
            allowColumnResizing={true}
            stateStoring={{ enabled: 'true',type: 'localStorage', storageKey: saveKey }}
            height={getHeight}
            width="100%" ref={(gv) => {this.grid = gv}}
         >
         {this.props.pagingEnabled ? 
         <Paging defaultPageSize={10} enabled={true} /> : <Scrolling mode="virtual" />
         }

        <Pager
        showPageSizeSelector={true}
        allowedPageSizes={[10, 20]}
        showInfo={true} />
        <GroupPanel visible={true} />
        <FilterRow visible={true} />
        <FilterPanel visible ={true} />
        <ColumnChooser enabled={true} />
        {/* <StateStoring enabled={true} storageKey="this.props.sectionId" /> */}


        <Column dataField={'identifier'} cellTemplate={'linkCellTemplate'} dataType='string' visible={true} />
        <Column dataField={'title'} dataType='string' visible={true} />
        <Column caption="Est. Read Time(Mins)" calculateCellValue={this.calculateReadTime}></Column>
        <Column dataField={'issueNumber'} dataType='number' visible={false} />
        <Column dataField={'sectionTitle'} visible={false} dataType='string' />
        <Column dataField={'companyShortDescription'} caption='Entity' dataType='string' visible={false} />
        <Column dataField={'sectionEntityShortDescription'} caption='Section Entity' dataType="string" visible={false} />
        <Column dataField={'issueDate'} dataType="date" format="dd/MM/y" visible={false} />
        <Column dataField={'externalApproval'} caption="NAA Approval" visible={false} dataType='boolean'  />
        <Column dataField={'revisionDate'} format="dd/MM/y" dataType="date" visible={false} />
        <Column dataField={'revisionNumber'} dataType="number" visible={false} />
        <Column type={'buttons'} width={75} allowReordering={false} showInColumnChooser={false} allowResizing={false}
          buttons={[ {
            text: 'Read and Sign',
            hint: 'Read and Sign',
            icon: 'ion ion-md-thumbs-up',
            onClick: this.clickHandler,
            visible: this.showThumbIcon
          },
          {
            text: 'Read and Sign',
            hint: 'Read and Sign',
            icon: 'comment',
            onClick: this.clickHandler,
            visible: this.showFeedbackIcon
          },
         
          ]} />


        {/* <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} /> */}
        {/* <Paging defaultPageSize={10} /> */}

         <Template name={'linkCellTemplate'} render={LinkCell} />
        </DataGrid>
        <ReadSignPopup
        popupVisible={true}
        closeOnOutsideClick={true}
        hidingPopupHandler={this.hidingPopupHandler}
        readSignCompleted={this.readSignCompletedHandler}
        >
       </ReadSignPopup>
        </Holder>);
      }



}

const mapStateToProps = (state) => {
  return {
      user: state.oidc.user,
   //   popupVisible: state.document.readAndSigning,
      pagingEnabled: state.prefs.pagingEnabled
  };
}

const mapDispatchToProps = dispatch => {
  return {
      onShowingReadAndSignPopup: (rowData) => dispatch(actions.showReadAndSignPopup(rowData)),
      onHidingReadAndSignPopup: () => dispatch(actions.hideReadAndSignPopup()),
      onInitBadgeCount: () => dispatch(actions.initBadgeCountsAsync())
  };
}

export default connect(mapStateToProps,mapDispatchToProps)(ReadAndSignGrid);