import React, {Component} from 'react';
import {Popup, Button, List} from 'devextreme-react';
import OwnerTemplate from '../OwnerTemplate/OwnerTemplate';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { connect } from "react-redux";
import * as apis from '../../utils/apis';
import {animOff} from '../../utils/appConfig'

const getHistoryDataSource = (token,id) => {
    return createStore({
      key: 'id',
      loadUrl: apis.MANUALAPI + 'api/clientowners/' + id,
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }


class OwnerPopup extends Component {

    state={
        loading : true,
        owners: [],
        docId: null
    };

 
    render() {

    
            let owners = <List dataSource={getHistoryDataSource(this.props.token,this.props.rowData.id)}
             itemComponent={OwnerTemplate}
             height="100%"
             noDataText="No owners are defined for this document"></List> 
 

        return (
            <Popup visible={this.props.popupVisible} title={"Owners For Document " + this.props.rowData.identifier} closeOnOutsideClick={true} onHidden={this.props.hidingPopupHandler} animation={animOff} showCloseButton={true}>
       
            <div style={{height: "85%"}}>
            {owners}
            </div>
            <div style={{float : "right", marginTop: "10px"}}>
            <span><Button text="OK" type="success" onClick={this.props.hidingPopupHandler}></Button></span>
            </div>
            </Popup>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        rowData:state.document.rowData
    };
}

export default connect(mapStateToProps)(OwnerPopup)
