import React from 'react';
import {List} from 'devextreme-react';
import ApprovalLine from '../ApprovalLine/ApprovalLine';

const approvalStatus = (props) => {
//    console.log(props.approvals);
    return (<List dataSource={props.approvals}
    itemComponent={ApprovalLine}
    noDataText="No approvals are set for this change."></List> 
        )
    
};

export default approvalStatus;