import React from 'react';
import Holder from '../../hoc/holder';
import { Button } from 'devextreme-react';
import './OwnerTemplate.css'

const ownerTemplate = (data) => {
    return (
        <Holder>
            <div>{data.data.isPrimary ? <Button icon="primaryOwner" disabled={true}></Button> : <Button icon="user" disabled={true}></Button>}  {data.data.jobTitleRoleName} - {data.data.fullName}</div>
        </Holder>
    );

}

export default ownerTemplate;