import React, {PureComponent} from 'react';
import { withRouter} from 'react-router-dom';
import LinkCell from '../../../components/LinkCell/LinkCell';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { connect } from "react-redux";
import * as apis from '../../../utils/apis';

import DataGrid, {
    Column,
    GroupPanel,
    FilterPanel,
    FilterRow,
    ColumnChooser,
    Scrolling,
    Pager,
    Paging
} from 'devextreme-react/ui/data-grid';

import { Template } from 'devextreme-react/core/template';


const getHeight = () => {
    return (window.innerHeight - 120)
}

const getReadingListDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: apis.MANUALAPI + 'api/readinglist/getreadinglist',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

class ReadingListGridComponent extends PureComponent {

    componentDidMount() {
        window.addEventListener('resize', this.resize)  
    }

    resize = () => {
    this.gridview.instance.option('height',getHeight());
    }

    changePossible = (e) => {
      if(e.row.data.primary === null) {
        return false;
      }
      return true;
    }

    componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
    }

    switchClicked = (e) => {
        this.props.history.push('/readinglistadmin');
      }

       onToolbarPreparing = (e) =>  {
        if(this.props.groupOwner !== true) {
          return;
        }
        e.toolbarOptions.items.unshift({
            location: 'before',
    
        },
        {
            location: 'after',
            widget: 'dxButton',
            options: {
              width: 136,
              text: 'Edit',
              icon: 'preferences',
              onClick: this.switchClicked,
            }
          },
        );
      }

    render() {
      let saveKey = 'clrlist';
      if(this.props.pagingEnabled === true) {
        saveKey = 'clrlistPaged';
      }  


        return (
            <DataGrid 
            dataSource={getReadingListDataSource(this.props.user.access_token)}  
            rowAlternationEnabled={true}  
            noDataText="Your reading list is empty."
            showBorders={true}
            showRowLines={true}
            showColumnLines={true}  
            columnHidingEnabled={false}
            allowColumnReordering={true}
            allowColumnResizing={true}
            stateStoring={{ enabled: 'true',type: 'localStorage', storageKey: saveKey }}
            height={getHeight}
            width="100%" ref={(gv) => {this.gridview = gv}}
            onToolbarPreparing={(e) => this.onToolbarPreparing(e)}
         >
         {this.props.pagingEnabled ? 
         <Paging defaultPageSize={10} enabled={true} /> : <Scrolling mode="virtual" />
         }

        <Pager
        showPageSizeSelector={true}
        allowedPageSizes={[10, 20]}
        showInfo={true} />
        <GroupPanel visible={true} />
        <FilterRow visible={true} />
        <FilterPanel visible ={true} />
        <ColumnChooser enabled={true} />
        {/* <StateStoring enabled={true} storageKey="this.props.sectionId" /> */}


        <Column dataField={'identifier'} cellTemplate={'linkCellTemplate'} dataType="string" visible={true} />
        <Column dataField={'title'} dataType="string" visible={true} />
        <Column dataField={'issueNumber'} dataType="number" visible={false} />
        <Column dataField={'sectionTitle'} visible={false} dataType="string" />
        <Column dataField={'companyShortDescription'} caption='Entity' dataType="string" visible={false} />
        <Column dataField={'sectionEntityShortDescription'} caption='Section Entity' dataType="string" visible={false} />
        <Column dataField={'issueDate'} dataType="date" format="dd/MM/y" visible={false} />
        <Column dataField={'externalApproval'} caption="NAA Approval" visible={false} dataType="boolean" />
        <Column dataField={'revisionDate'} format="dd/MM/y" dataType="date" visible={false} />
        <Column dataField={'revisionNumber'} dataType="number" visible={false} />
        <Column type={'buttons'} width={150} allowReordering={false} showInColumnChooser={false} allowResizing={false}
          buttons={[ {
            text: 'Favourite',
            hint: 'Favourite',
            icon: 'ion ion-md-heart',
            onClick: this.props.favouriteClickHandler
          },
          {
            text: 'History',
            hint: 'History',
            icon: 'box',
            onClick: this.props.historyClickHandler
          },
          {
            text: 'Ownership',
            hint: 'Ownership',
            icon: 'card',
            onClick: this.props.ownerClickHandler
          },
          {
            text: 'Change Request',
            hint: 'Change Request',
            icon: 'ion ion-md-document',
            onClick: this.props.changeRequestClickHandler,
            visible: this.changePossible
          },
          ]} />


        {/* <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} /> */}
        {/* <Paging defaultPageSize={10} /> */}

         <Template name={'linkCellTemplate'} render={LinkCell} />
        </DataGrid>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.oidc.user,
        pagingEnabled: state.prefs.pagingEnabled
    };
  }
  

export default withRouter(connect(mapStateToProps)(ReadingListGridComponent));