
import React, {PureComponent} from 'react';
import LinkCell from '../../components/LinkCell/LinkCell';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { connect } from "react-redux";
import * as apis from '../../utils/apis';

import DataGrid, {
    Column,
    GroupPanel,
    FilterPanel,
    FilterRow,
    ColumnChooser,
    Scrolling,
    Pager,
    Paging
} from 'devextreme-react/ui/data-grid';

import { Template } from 'devextreme-react/core/template';

const getHeight = () => {
    return (window.innerHeight - 120)
}

const getUpdateDataSource = (token) => {
  return createStore({
    key: 'id',
    loadUrl: apis.MANUALAPI + 'api/updates/',
    onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers =  {'Authorization': "bearer " + token} 
      }
    }
  )
}

class UpdateGridComponent extends PureComponent {

resize = () => {
        this.gridview.instance.option('height',getHeight());
}

componentDidMount() {
     window.addEventListener('resize', this.resize)
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }
  
  changePossible = (e) => {
    if(e.row.data.primary === null) {
      return false;
    }
    return true;
  }

render () {
  let saveKey = 'clUpdates';
      if(this.props.pagingEnabled === true) {
        saveKey = 'clUpdatesPaged';
      }  

    return (
    <DataGrid 
    dataSource={getUpdateDataSource(this.props.user.access_token)}  
    noDataText="There are no recent updates."
    rowAlternationEnabled={true}  
    showBorders={true}
    showRowLines={true}
    showColumnLines={true}  
    allowColumnReordering={true}
    allowColumnResizing={true}
    stateStoring={{ enabled: 'true',type: 'localStorage', storageKey: saveKey }}
    height={getHeight}
    width="100%" ref={(gv) => {this.gridview = gv}} 
    >
     {this.props.pagingEnabled ? 
         <Paging defaultPageSize={10} enabled={true} /> : <Scrolling mode="virtual" />
         }
         

        <Pager
        showPageSizeSelector={true}
        allowedPageSizes={[10, 20]}
        showInfo={true} />

    <GroupPanel visible={true} />
    <FilterRow visible={true} />
    <FilterPanel visible ={true} />
    <ColumnChooser enabled={true} />
    {/* <StateStoring enabled={true} storageKey="this.props.sectionId" /> */}


    <Column dataField={'identifier'} cellTemplate={'linkCellTemplate'} dataType="string" visible={true} />
    <Column dataField={'title'} dataType="string" visible={true} />
    <Column dataField={'editableCopy'} visible={false} showInColumnChooser={false} dataType="string" />
    <Column dataField={'issueNumber'} dataType="number" visible={true} />
    <Column dataField={'sectionTitle'} visible={false} dataType="string" />
    <Column dataField={'companyShortDescription'} caption='Entity' dataType="string" visible={false} />
    <Column dataField={'sectionEntityShortDescription'} caption='Section Entity' dataType="string" visible={false} />
    <Column dataField={'issueDate'} format="dd/MM/y" dataType="date" visible={true} />
    <Column dataField={'revisionDate'} format="dd/MM/y" dataType="date" visible={false} />
    <Column dataField={'revisionNumber'} dataType="number" visible={false} />
    <Column dataField={'externalApproval'} caption="NAA Approval" dataType="boolean" visible={false} />
    <Column type={'buttons'} width={150} allowReordering={false} showInColumnChooser={false} allowResizing={false}
    buttons={[ {
    text: 'Favourite',
    hint: 'Favourite',
    icon: 'ion ion-md-heart',
    onClick: this.props.favouriteClickHandler
    },
    {
    text: 'History',
    hint: 'History',
    icon: 'box',
    onClick: this.props.historyClickHandler
    },
    {
    text: 'Ownership',
    hint: 'Ownership',
    icon: 'card',
    onClick: this.props.ownerClickHandler
    },
    {
    text: 'Change Request',
    hint: 'Change Request',
    icon: 'ion ion-md-document',
    onClick: this.props.changeRequestClickHandler,
    visible: this.changePossible
    },
    ]} />
    <Template name={'linkCellTemplate'} render={LinkCell} />
    </DataGrid>
        )
    }

    }

const mapStateToProps = (state) => {
    return {
        user: state.oidc.user,
        pagingEnabled: state.prefs.pagingEnabled
    };
  }
  

export default connect(mapStateToProps)(UpdateGridComponent);
