import * as actionTypes from '../actions/actionTypes';

const initialState = {
    entityId: null
}

const entityReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.CHANGE_ENTITY:
         return {
             ...state,
             entityId : action.val
         }
         default:
         return state;
    }
};

export default entityReducer;