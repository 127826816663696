import React, {PureComponent} from 'react';
import LinkCell from '../../components/LinkCell/LinkCell';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { connect } from "react-redux";
import * as apis from '../../utils/apis';


import DataGrid, {
    Column,
    GroupPanel,
    FilterPanel,
    FilterRow,
    ColumnChooser,
    Scrolling,
    Pager,
    Paging
} from 'devextreme-react/ui/data-grid';

import { Template } from 'devextreme-react/core/template';

const getHeight = () => {
    return (window.innerHeight - 120)
}

const getFavouritesDataSource = (token) => {
  return createStore({
    key: 'id',
    loadUrl: apis.MANUALAPI + 'api/favourites/',
    onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers =  {'Authorization': "bearer " + token} 
      }
    }
  )
}

class FavouriteGridComponent extends PureComponent {

    resize = () => {
        this.gridview.instance.option('height',getHeight());
      }

      changePossible = (e) => {
        if(e.row.data.primary === null) {
          return false;
        }
        return true;
      }
    
      componentDidMount() {
        window.addEventListener('resize', this.resize);
      }

      componentDidUpdate() {
        this.gridview.instance.refresh();
      }
      
      componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
      }

      removeFavouriteClickHandler = (e) => {
        e.event.preventDefault();
        this.props.removeFavouriteClickHandler(e);
        
 
      }

    render() {
      let saveKey = 'favs';
      if(this.props.pagingEnabled === true) {
        saveKey = 'favsPaged';
      }  

        return(
            <DataGrid 
            dataSource={getFavouritesDataSource(this.props.user.access_token)}  
            rowAlternationEnabled={true}  
            noDataText="You have no favourites."
            showBorders={true}
            showRowLines={true}
            showColumnLines={true}  
            columnHidingEnabled={false}
            allowColumnReordering={true}
            allowColumnResizing={true}
             stateStoring={{ enabled: 'true',type: 'localStorage', storageKey: saveKey }}
            height={getHeight}
            width="100%" ref={(gv) => {this.gridview = gv}}
         >
         {this.props.pagingEnabled ? 
         <Paging defaultPageSize={10} enabled={true} /> : <Scrolling mode="virtual" />
         }
         

        <Pager
        showPageSizeSelector={true}
        allowedPageSizes={[10, 20]}
        showInfo={true} />
        <GroupPanel visible={true} />
        <FilterRow visible={true} />
        <FilterPanel visible ={true} />
        <ColumnChooser enabled={true} />
        {/* <StateStoring enabled={true} storageKey="this.props.sectionId" /> */}


        <Column dataField={'identifier'} cellTemplate={'linkCellTemplate'} visible={true} dataType='string' />
        <Column dataField={'title'} visible={true} dataType='string' />
        <Column dataField={'issueNumber'} visible={false} dataType='number' />
        <Column dataField={'sectionTitle'} visible={false} dataType='string' />
        <Column dataField={'companyShortDescription'} caption='Entity' visible={false} dataType='string' />
        <Column dataField={'sectionEntityShortDescription'} caption='Section Entity' visible={false} dataType='string' />
        <Column dataField={'issueDate'} dataType="date" format="dd/MM/y" visible={false} />
        <Column dataField={'externalApproval'} caption="NAA Approval" visible={false} dataType='boolean' />
        <Column dataField={'revisionDate'} format="dd/MM/y" dataType="date" visible={false} />
        <Column dataField={'revisionNumber'} dataType="number" visible={false} />
        <Column type={'buttons'} width={150} allowReordering={false} showInColumnChooser={false} allowResizing={false}
          buttons={[ {
            text: 'Favourite',
            hint: 'Favourite',
            icon: 'ion ion-md-heart-dislike',
            onClick: this.removeFavouriteClickHandler
          },
          {
            text: 'History',
            hint: 'History',
            icon: 'box',
            onClick: this.props.historyClickHandler
          },
          {
            text: 'Ownership',
            hint: 'Ownership',
            icon: 'card',
            onClick: this.props.ownerClickHandler
          },
          {
            text: 'Change Request',
            hint: 'Change Request',
            icon: 'ion ion-md-document',
            onClick: this.props.changeRequestClickHandler,
            visible: this.changePossible
          },
          ]} />
        
         <Template name={'linkCellTemplate'} render={LinkCell} />
        </DataGrid>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.oidc.user,
        removingFavourite: state.document.removingFavourite,
        pagingEnabled: state.prefs.pagingEnabled
    };
  }
  

export default connect(mapStateToProps)(FavouriteGridComponent);

