import * as actionTypes from '../actions/actionTypes';

const initialState = {
    pagingEnabled: null
}

const prefsReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.PAGINGON:
         return {
             ...state,
             pagingEnabled : true
         }
         case actionTypes.PAGINGOFF:
         return {
             ...state,
             pagingEnabled : false
         }
         default:
         return state;
    }
};

export default prefsReducer;