import React, {PureComponent} from 'react';
import {Popup, Button} from 'devextreme-react';
import { connect } from "react-redux";
import * as actions from '../../store/actions/actions';
import Holder from '../../hoc/holder';
import {animOff} from '../../utils/appConfig'

class AddFavouritePopup extends PureComponent {

   

       

    render() {

        let popup = null;

        if(this.props.rowData) {
           popup =  <Popup visible={this.props.popupVisible} title={"Add Favourite " + this.props.rowData.identifier} closeOnOutsideClick={true} onHidden={this.props.hidingPopupHandler} animation={animOff} showCloseButton={true} >
       
        <div>
        <p>You are adding {this.props.rowData.identifier} - {this.props.rowData.title} for entity {this.props.rowData.companyShortDescription} held in section {this.props.rowData.sectionTitle} to your favourites</p>
        <p>Please click Add if you want to do this, or cancel if you disagree.</p>
        </div>
        <div style={{float : "right", marginTop: "10px"}}>
        <span><Button text="Cancel" type="danger" onClick={this.props.hidingPopupHandler}></Button></span>
        <span style ={{marginLeft: "15px"}}><Button text="Add" type="success" onClick={() => this.props.onAddingFavourite(this.props.rowData.id) }></Button></span>
        </div>
        </Popup>
        }
        
        return (
            <Holder>{popup}</Holder>
           
        );
    }

}

const mapDispatchToProps = dispatch => {
    return {
        onAddingFavourite: (docId) => dispatch(actions.addFavouriteAsync(docId)),
    };
  }

 const mapStateToProps = (state) => {
    return {
        rowData:state.document.rowData
    };
}
export default connect(mapStateToProps,mapDispatchToProps)(AddFavouritePopup);