import * as actionTypes from '../actions/actionTypes';

const initialState = {
    addingFavourite: false,
    viewingHistory: false,
    requestingChange: false,
    viewingOwners: false,
    removingFavourite: false,
    readAndSigning: false,
    reviewing: false,
    rowData: null
}

const documentReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SHOW_FAVOURITE_POPUP:
            return {
                ...state,
                addingFavourite: true,
                rowData: action.rowData
            }
        case actionTypes.HIDE_FAVOURITE_POPUP:
            return {
                ...state,
                addingFavourite: false,
                rowData: null
            }
        case actionTypes.SHOW_REMOVE_FAVOURITE_POPUP:
            return {
                ...state,
                removingFavourite: true,
                rowData: action.rowData
            }
        case actionTypes.HIDE_REMOVE_FAVOURITE_POPUP:
            return {
                ...state,
                removingFavourite: false,
                rowData: null
            }
         case actionTypes.SHOW_READ_AND_SIGN_POPUP:
         return {
             ...state,
             readAndSigning: true,
             rowData: action.rowData
         }
         case actionTypes.HIDE_READ_AND_SIGN_POPUP:
         return { 
            ...state,
            readAndSigning: false,
            rowData: null
         }
         case actionTypes.SHOW_REVIEW_POPUP:
         return {
             ...state,
             reviewing: true,
             rowData: action.rowData
         }
         case actionTypes.HIDE_REVIEW_POPUP:
         return {
             ...state,
             reviewing: false,
             rowData: null
         }
         case actionTypes.SHOW_CHANGE_POPUP:
         return {
             ...state,
             requestingChange: true,
             rowData: action.rowData
         }
     case actionTypes.HIDE_CHANGE_POPUP:
         return {
             ...state,
             requestingChange: false,
             rowData: null
         }
     case actionTypes.SHOW_HISTORY_POPUP:
         return {
             ...state,
             viewingHistory: true,
             rowData: action.rowData
         }
     case actionTypes.HIDE_HISTORY_POPUP:
         return {
             ...state,
             viewingHistory: false,
             rowData: null
         }
     case actionTypes.SHOW_OWNER_POPUP:
         return {
             ...state,
             viewingOwners: true,
             rowData: action.rowData
         }
     case actionTypes.HIDE_OWNER_POPUP:
         return {
             ...state,
             viewingOwners: false,
             rowData: null
         }
         default:
         return state;
    }
};

export default documentReducer;

