
// entity actions
export const CHANGE_ENTITY = 'CHANGE_ENTITY';


// document actions.
export const ADD_FAVOURITE = 'ADD_FAVOURITE';
export const REMOVE_FAVOURITE = 'REMOVE_FAVOURITE';

export const ADD_FAVOURITE_FAILED = 'ADD_FAVOURITE_FAILED';
export const REMOVE_FAVOURITE_FAILED = 'REMOVE_FAVOURITE_FAILED';

export const HIDE_FAVOURITE_POPUP = 'HIDE_FAVOURITE_POPUP';
export const SHOW_FAVOURITE_POPUP = 'SHOW_FAVOURITE_POPUP';

export const SHOW_REMOVE_FAVOURITE_POPUP = 'SHOW_REMOVE_FAVOURITE_POPUP';
export const HIDE_REMOVE_FAVOURITE_POPUP = 'HIDE_REMOVE_FAVOURITE_POPUP';

export const READ_AND_SIGN = 'READ_AND_SIGN';
export const READ_AND_SIGN_FAILED = 'READ_AND_SIGN_FAILED';
export const ACTION_CHANGE_REQUEST = 'ACTION_CHANGE_REQUEST';
export const ACTION_CHANGE_REQUEST_FAILED = 'ACTION_CHANGE_REQUEST_FAILED';

export const SHOW_READ_AND_SIGN_POPUP = 'SHOW_READ_AND_SIGN_POPUP';
export const HIDE_READ_AND_SIGN_POPUP = 'HIDE_READ_AND_SIGN_POPUP';

export const SHOW_REVIEW_POPUP = 'SHOW_REVIEW_POPUP';
export const HIDE_REVIEW_POPUP = 'HIDE_REVIEW_POPUP';

export const REVIEW_DOC = 'REVIEW_DOC';
export const REVIEW_DOC_FAILED = 'REVIEW_DOC_FAILED';

export const SHOW_CHANGE_POPUP = 'SHOW_CHANGE_POPUP';
export const HIDE_CHANGE_POPUP = 'HIDE_CHANGE_POPUP';

export const SHOW_UPDATE_FORM = 'SHOW_UPDATE_FORM';
export const SHOW_ADD_FORM = 'SHOW_ADD_FORM';
export const SHOW_DELETE_FORM = 'SHOW_DELETE_FORM';

export const HIDE_UPDATE_FORM = 'HIDE_UPDATE_FORM';
export const HIDE_ADD_FORM = 'HIDE_ADD_FORM';
export const HIDE_DELETE_FORM = 'HIDE_DELETE_FORM';

export const RESET_CHANGE_FORM = 'RESET_CHANGE_FORM';

export const SHOW_HISTORY_POPUP = 'SHOW_HISTORY_POPUP';
export const HIDE_HISTORY_POPUP = 'HIDE_HISTORY_POPUP';

export const SHOW_OWNER_POPUP = 'SHOW_OWNER_POPUP';
export const HIDE_OWNER_POPUP = 'HIDE_OWNER_POPUP';

export const SHOW_CANCEL_POPUP = 'SHOW_CANCEL_POPUP';
export const HIDE_CANCEL_POPUP = 'HIDE_CANCEL_POPUP';

export const SET_BADGE_COUNTS = 'SET_BADGE_COUNTS';
export const FETCH_BADGE_COUNTS_FAILED = 'FETCH_BADGE_COUNTS_FAILED';

export const SHOW_VIEW_CHANGE_POPUP = 'SHOW_VIEW_CHANGE_POPUP';
export const HIDE_VIEW_CHANGE_POPUP = 'HIDE_VIEW_CHANGE_POPUP';

export const SHOW_APPROVAL_POPUP = 'SHOW_APPROVAL_POPUP';
export const HIDE_APPROVAL_POPUP = 'HIDE_APPROVAL_POPUP';

export const SELECT_HISTORY = 'SELECT_HISTORY';

export const SEARCHING = 'SEARCHING';

export const LOADINGSECTION = 'LOADINGSECTION';

export const PAGINGON = 'PAGINGON';
export const PAGINGOFF = 'PAGINGOFF';


