import React, {Component, Fragment} from 'react';
import {Popup, ScrollView} from 'devextreme-react';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { connect } from "react-redux";
import * as apis from '../../utils/apis';
import {animOff} from '../../utils/appConfig';
import * as actions from '../../store/actions/actions';

import DataGrid, {
    Column,
    Scrolling,
    GroupPanel,
    FilterPanel,
    FilterRow,
    ColumnChooser,
    Pager,
    Paging
} from 'devextreme-react/ui/data-grid';
import ClientChangeForm from './ClientChangeForm/ClientChangeForm';


const getHistoryDataSource = (token,id) => {
    return createStore({
      key: 'id',
      loadUrl: apis.MANUALAPI + 'api/clienthistory/' + id,
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

const getHeight = () => {
    return (window.innerHeight * 0.9)
}

const getWidth = () => {
  return (window.innerWidth * 0.9)
}

class HistoryPopup extends Component {
    viewChangeRequestClickHandler = (e) => {
        e.event.preventDefault();
        if(e.row.data.changeRequestId) {
            this.props.onSelectingHistory(e.row.data);
            this.changepopup.instance.option('title', 'Change Request Form #' + e.row.data.changeRequestId)
            this.changepopup.instance.show();
        }
    }

    changePopupHiding = () => {
      this.props.onSelectingHistory(null);
    }

    isChangeRequestVisible = (e) => {
      return !e.row.isEditing && (e.row.data.changeRequestId);
    }
    
    render() {
      let saveKey = 'histgrid';
      if(this.props.pagingEnabled === true) {
        saveKey = 'histgridpaged';
      }  
     
        let dataGrid = <Fragment><DataGrid 
        dataSource={getHistoryDataSource(this.props.token,this.props.rowData.id)}  
        rowAlternationEnabled={true}  
        showBorders={true}
        showRowLines={true}
        showColumnLines={true}  
        allowColumnReordering={true}
        allowColumnResizing={true}
        wordWrapEnabled={true}
        height="100%"
        width="100%"
        stateStoring={{ enabled: 'true',type: 'localStorage', storageKey: saveKey + this.props.sectionId}}
        >
        {this.props.pagingEnabled ? 
         <Paging defaultPageSize={10} enabled={true} /> : <Scrolling mode="virtual" />
         }

        <Pager
        showPageSizeSelector={true}
        allowedPageSizes={[10, 20]}
        showInfo={true} />

        <GroupPanel visible={true} />
        <FilterRow visible={true} />
        <FilterPanel visible ={true} />
        <ColumnChooser enabled={true} />

        <Column dataField={'issueNumber'} caption="Issue" />
        <Column dataField={'issueDate'} dataType="date" format="dd/MM/y" />
        <Column dataField={'action'} />
        <Column dataField={'changeReason'} />
        <Column dataField={'performedBy'} />
        <Column dataField={'docIdentifier'} visible={false} />
        <Column dataField={'docTitle'} visible={false} />
        <Column dataField={'changeDate'} format="dd/MM/y" dataType="date" visible={false} />
        <Column dataField={'documentSectionTitle'} visible={false} caption="Section"/>
        <Column dataField={'companyShortDescription'} caption='Entity' visible={false}/>
        <Column dataField={'revisionDate'} format="dd/MM/y" dataType="date" visible={false} />
        <Column dataField={'revisionNumber'} dataType="number" visible={false} />
        <Column type={'buttons'} allowReordering={false} showInColumnChooser={false} allowResizing={false} 
          buttons={[  {
            text: 'View Change Request',
            hint: 'View Change Request',
            icon: 'ion ion-md-document',
            onClick: this.viewChangeRequestClickHandler,
            visible: this.isChangeRequestVisible
          }, 
          ]} />
        </DataGrid>
        <Popup closeOnOutsideClick={true} ref={(popup) => {this.changepopup = popup}} onHiding={this.changePopupHiding} showCloseButton={true}>
        <ScrollView>
          <ClientChangeForm />
          </ScrollView>
        </Popup>
      </Fragment>
        if(this.props.rowData === null)
        {
            dataGrid = null;
        }

        return (
            <Fragment>
            <Popup visible={this.props.popupVisible} height={getHeight()} width={getWidth()}
             title={"History For Document " + this.props.rowData.identifier}
             closeOnOutsideClick={true}
             onHidden={this.props.hidingPopupHandler}
             animation={animOff} showCloseButton={true} >
       
              {dataGrid}
      
            </Popup>
          
            </Fragment>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        rowData:state.document.rowData,
        pagingEnabled: state.prefs.pagingEnabled
    };
}

const mapDispatchToProps = dispatch => {
  return {
    onSelectingHistory: (rowData) => dispatch(actions.selectHistory(rowData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HistoryPopup);