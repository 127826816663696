import React, {Component} from 'react';
import Holder from '../../hoc/holder';
import RemoveFavouritePopup from '../../components/RemoveFavouritePopup/RemoveFavouritePopup';
import HistoryPopup from '../../components/HistoryPopup/HistoryPopup';
import OwnerPopup from '../../components/OwnerPopup/OwnerPopup';
import ChangeRequestPopup from '../../components/ChangeRequestPopup/ChangeRequestPopup';
import { connect } from "react-redux";
import * as actions from '../../store/actions/actions';
import FavouriteGridComponent from './FavouriteGridComponent';

class FavouritesGrid extends Component {

      removeFavouriteClickHandler = (e) => {
        e.event.preventDefault();
        this.props.onShowingRemoveFavouritePopup(e.row.data);
      }

      historyClickHandler = (e) => {
        e.event.preventDefault();
        this.props.onShowingHistoryPopup(e.row.data);
      }

      ownerClickHandler = (e) => {
        e.event.preventDefault();
        this.props.onShowingOwnerPopup(e.row.data);
      }

      changeRequestClickHandler = (e) => {
        e.event.preventDefault();
        this.props.onShowingChangePopup(e.row.data);
      }

      hidingRemoveFavouritePopupHandler = () => {
        this.props.onHidingRemoveFavouritePopup();
      }

      hidingChangePopupHandler = () => {
        this.props.onHidingChangePopup();
        this.props.onResetingChangeForm();
      }

      hidingHistoryPopupHandler = () => {
        this.props.onHidingHistoryPopup();
      }

      hidingOwnerPopupHandler = () => {
        this.props.onHidingOwnerPopup();
      }

      render() {
        let popup =  <RemoveFavouritePopup
        popupVisible={this.props.removingFavourite}
        rowData={this.props.rowData}
        closeOnOutsideClick={true}
        hidingPopupHandler={this.hidingRemoveFavouritePopupHandler}
        >
       </RemoveFavouritePopup>

        if(this.props.removingFavourite === false)
        {
            popup = null;
        }

        let ownerPopup = <OwnerPopup
        popupVisible={this.props.viewingOwners}
        closeOnOutsideClick={true}
        hidingPopupHandler={this.hidingOwnerPopupHandler}
        token={this.props.user.access_token}
        >
          </OwnerPopup>

          if(this.props.viewingOwners === false)
          {
              ownerPopup = null;
          }

          let historyPopup = <HistoryPopup
          popupVisible={this.props.viewingHistory}
          closeOnOutsideClick={true}
          hidingPopupHandler={this.hidingHistoryPopupHandler}
          token = {this.props.user.access_token}
          >
          </HistoryPopup>

          if(this.props.viewingHistory === false)
          {
              historyPopup = null;
          }

          let changeRequestPopup = <ChangeRequestPopup
          popupVisible={this.props.requestingChange}
          closeOnOutsideClick={true}
          hidingPopupHandler={this.hidingChangePopupHandler}
          />

          if(this.props.requestingChange === false)
          {
              changeRequestPopup = null;
          }
  
          
          return(
          <Holder>
         <FavouriteGridComponent 
         removeFavouriteClickHandler={this.removeFavouriteClickHandler}
         historyClickHandler={this.historyClickHandler}
         ownerClickHandler={this.ownerClickHandler}
         changeRequestClickHandler={this.changeRequestClickHandler} />
        { changeRequestPopup }
        { historyPopup }
        { ownerPopup }
        {popup}
        </Holder>);
      }
}

const mapStateToProps = (state) => {
  return {
      user: state.oidc.user,
      removingFavourite: state.document.removingFavourite,
      requestingChange: state.document.requestingChange,
      viewingHistory: state.document.viewingHistory,
      viewingOwners: state.document.viewingOwners
  };
}

const mapDispatchToProps = dispatch => {
  return {
      onShowingRemoveFavouritePopup: (rowData) => dispatch(actions.showRemoveFavouritePopup(rowData)),
      onHidingRemoveFavouritePopup: () => dispatch(actions.hideRemoveFavouritePopup()),
      onShowingChangePopup: (rowData) => dispatch(actions.showChangePopup(rowData)),
      onHidingChangePopup: () => dispatch(actions.hideChangePopup()),
      onShowingHistoryPopup: (rowData) => dispatch(actions.showHistoryPopup(rowData)),
      onHidingHistoryPopup: () => dispatch(actions.hideHistoryPopup()),
      onShowingOwnerPopup: (rowData) => dispatch(actions.showOwnerPopup(rowData)),
      onHidingOwnerPopup: () => dispatch(actions.hideOwnerPopup()),
      onResetingChangeForm: () => dispatch(actions.resetChangeForm())
  };
}

export default connect(mapStateToProps,mapDispatchToProps)(FavouritesGrid);