import React, {Component} from 'react';
import LinkCell from '../../../components/LinkCell/LinkCell';
import axios from 'axios';
import notify from 'devextreme/ui/notify';

import { createStore } from 'devextreme-aspnet-data-nojquery';
import { connect } from "react-redux";
import * as apis from '../../../utils/apis';
import * as appConfig from '../../../utils/appConfig';
import List from 'devextreme-react/list';
import DataGrid, {
    Column,
    FilterPanel,
    FilterRow,
    ColumnChooser,
    Scrolling,
    Selection,
    Pager,
    Paging
} from 'devextreme-react/ui/data-grid';

import { Template } from 'devextreme-react/core/template';

import './ReadingListAdmin.css';
import { Toolbar } from 'devextreme-react';

const getHeight = () => {
    return (window.innerHeight - 120)
}

const getListHeight = () => {
    return (window.innerHeight - 170)
}

const getReadingListDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: apis.MANUALAPI + 'api/clientdocuments/' + appConfig.ROOTSECTION,
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

const items = 
    [
        {
            location: 'center',
            locateInMenu: 'never',
            template: () => {
              return '<div class=\'toolbar-label\'><b>On Reading List</div>';
            }
        }
    ];


class ReadingListAdmin extends Component {
    
        state = {
            loading: true,
        }
        
        selected= [];
        ownedGroups = [];
        selectionText = [];
        groupId= '';

        componentDidMount() {

            axios.get('api/readinglist/GetOwnedGroups')
            .then(response => {
                this.ownedGroups = response.data;
                this.setState({ loading: false});
            });

            window.addEventListener('resize', this.resize);
             // get owned user groups and bind to select list.
            
            }
            
            componentWillUnmount() {
            window.removeEventListener('resize', this.resize);    
            }

        componentDidUpdate() {
            // get the selection from the grid.
            // post off to save.
            // update the grid.

        }

        switchClicked = (e) => {
            this.props.history.push('/readinglist');
        }

        saveEnabled = () => {
            return this.groupId !== '';
        }


        buttonClicked = (e) => {
            var selectedDocs = this.gridview.instance.option("selectedRowKeys");
           // console.log("groupId",this.groupId);
            if(this.groupId === '') {
                notify("Select a group","error", 2500); 
                return;
            }
            //console.log("selecteddocs: ", selectedDocs);
    
                var readingList = {
                    groupId: this.groupId,
                    selectedDocIds: selectedDocs
                }
                // save the updated reading list to API.
                axios.put('api/readinglist/UpdateReadingList', readingList)
                .then(response => {
                    notify("Reading List Updated","success", 1500);   
                   
                })
          }

        onToolbarPreparing = (e, items) =>  {
            e.toolbarOptions.items.unshift({
                location: 'before',
        
            },{
              location: 'before',
              widget: 'dxSelectBox',
              options: {
                width:  function() {
                    return window.innerWidth / 4;
                },
                items: items,
                displayExpr: 'text',
                valueExpr: 'value',
                value: this.groupId,
                placeholder: 'Select User Group',
                onValueChanged: this.groupChanged
              }
            }, {
              location: 'before',
              widget: 'dxButton',
              options: {
                disabled: this.state.saveEnabled,
                width: 136,
                text: 'Update',
                onClick: this.buttonClicked,
                type: "success"
              }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                  width: 136,
                  text: 'User',
                  icon: 'group',
                  onClick: this.switchClicked,
                }
              },
            );
          }

        onContentReady = (e) => {
            e.component.selectRows(this.selected, true);

        }

        onSelectionChanged = (e) => {
           // console.log(e.selectedRowsData);
            this.selectionText = (e.selectedRowsData.map(a => a.identifier)).sort();
           // console.log(this.selectionText);
            this.selectedList.instance.option('items',this.selectionText);

        }

        groupChanged = (e) => {
           // this.selectedList.instance.option('items',this.selectionText);

            this.gridview.instance.clearSelection();
            if(e.selectedItem !== null) {
                axios.get('api/readinglist/getselectedDocs' + '?groupId=' + e.value)
                .then(response => {
                    this.selected = response.data;
                    this.gridview.instance.selectRows(this.selected);
                 //   console.log(this.gridview.instance);
                    this.groupId = e.value;
                });

            }
        }

        resize = () => {
            this.gridview.instance.option('height',getHeight());
        }
  
        render() {

      
        let grid =  
        <div id="rladmin">
            <div id="rlgrid">
        <DataGrid 
            dataSource={getReadingListDataSource(this.props.user.access_token)}  
            rowAlternationEnabled={true}  
            showBorders={true}
            showRowLines={true}
            showColumnLines={true}  
            columnHidingEnabled={false}
            allowColumnReordering={true}
            allowColumnResizing={true}
            height={getHeight}
            width="100%" ref={(gv) => {this.gridview = gv}}
            onToolbarPreparing={(e,items) => this.onToolbarPreparing(e, this.ownedGroups)}
            onContentReady={(e) => this.onContentReady(e)} onSelectionChanged={(e) => this.onSelectionChanged(e)}
            >
         {this.props.pagingEnabled ? 
         <Paging defaultPageSize={10} enabled={true} /> : <Scrolling mode="virtual" />
         }

        <Pager
        showPageSizeSelector={true}
        allowedPageSizes={[10, 20]}
        showInfo={true} />
        <Selection
            mode='multiple' showCheckBoxesMode={'always'} allowSelectAll={false}
            />
        <FilterRow visible={true} />
        <FilterPanel visible ={true} />
        <ColumnChooser enabled={true} />
        {/* <StateStoring enabled={true} storageKey="this.props.sectionId" /> */}


        <Column dataField={'identifier'} cellTemplate={'linkCellTemplate'} visible={true} dataType="string" />
        <Column dataField={'title'} dataType="string" visible={true} />
        <Column dataField={'issueNumber'} dataType="number" visible={false} />
        <Column dataField={'sectionTitle'} visible={false} dataType="string" />
        <Column dataField={'companyShortDescription'} caption='Entity' dataType="string" visible={false} />
        <Column dataField={'sectionEntityShortDescription'} caption='Section Entity' dataType="string" visible={false} />
        <Column dataField={'issueDate'} dataType="date" format="dd/MM/y" visible={false} />
        <Column dataField={'externalApproval'} caption="NAA Approval" visible={false} dataType='boolean' />
        


        {/* <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} /> */}
        {/* <Paging defaultPageSize={10} /> */}

            <Template name={'linkCellTemplate'} render={LinkCell} />
        
        </DataGrid>
     </div>
     <div id="rllist">
     <Toolbar items={items}>
         
     </Toolbar>
        <List
           
            height={getListHeight}
            allowItemDeleting={false}
            width="100%"
            //displayExpr='identifier'
            showSelectionControls={false}
            ref={(list) => {this.selectedList = list}}
            >
          </List>
          </div>
        </div>;

        return grid;
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.oidc.user,
        pagingEnabled: state.prefs.pagingEnabled

    };
}

export default connect(mapStateToProps,null)(ReadingListAdmin);