import React, {Component} from 'react';
import Holder from '../../../hoc/holder';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { connect } from "react-redux";
import * as apis from '../../../utils/apis';
import * as lookupData from '../../../utils/lookupData';
import RequestLinkCell from '../../../components/RequestLinkCell/RequestLinkCell';
import ApprovalPopup from '../../../components/ApprovalPopup/ApprovalPopup';
import ViewChangePopup from '../../../components/ViewChangePopup/ViewChangePopup';

import DataGrid, {
    Column,
    GroupPanel,
    FilterPanel,
    FilterRow,
    ColumnChooser,
    Scrolling,
    ColumnLookup,
    Pager,
    Paging
} from 'devextreme-react/ui/data-grid';
import { Template } from 'devextreme-react/core/template';

import * as actions from '../../../store/actions/actions';



const getMyChangeApprovalsDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: apis.MANUALAPI + 'api/changecontrol/getchangeapprovals',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
}

const getHeight = () => {
    return (window.innerHeight - 120)
}


class ChangeApproval extends Component {

    approvalClickHandler = (e) => {
        e.event.preventDefault();
        this.props.onShowingApprovalPopup(e.row.data);
    }

    hidingApprovalPopupHandler = () => {   
        this.props.onHidingApprovalPopup();

    }

    viewChangeClickHandler = (rowData) => {
        this.props.onShowingViewChangePopup(rowData)
    }

    hidingChangeRequestPopupHandler = () => {
        this.props.onHidingViewChangeForm();
    }

    resize = () => {
        this.gridview.instance.option('height',getHeight());
    }

    switchClicked = (e) => {
        this.props.history.push('/changes');
    }

    componentDidMount() {
        window.addEventListener('resize', this.resize)
    }
      
    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    onToolbarPreparing = (e) =>  {
        e.toolbarOptions.items.unshift({
            location: 'before',
    
        },
        {
            location: 'after',
            widget: 'dxButton',
            options: {
              width: 200,
              text: 'My Changes',
              icon: 'changerequest',
              onClick: this.switchClicked,
            }
          },
        );
      }

    render() {
        let saveKey = 'clReview';
        if(this.props.pagingEnabled === true) {
          saveKey = 'clReviewPaged';
        }  


        let grid =  
        <Holder>
        <DataGrid 
            dataSource={getMyChangeApprovalsDataSource(this.props.user.access_token)}  
            rowAlternationEnabled={true}  
            noDataText="You have no changes to action."
            showBorders={true}
            showRowLines={true}
            showColumnLines={true}  
            columnHidingEnabled={false}
            allowColumnReordering={true}
            allowColumnResizing={true}
            stateStoring={{ enabled: 'true',type: 'localStorage', storageKey: saveKey }}
            onToolbarPreparing={(e) => this.onToolbarPreparing(e)}
            height={getHeight}
            width="100%" ref={(gv) => {this.gridview = gv}}
         >
         {this.props.pagingEnabled ? 
         <Paging defaultPageSize={10} enabled={true} /> : <Scrolling mode="virtual" />
         }

        <Pager
        showPageSizeSelector={true}
        allowedPageSizes={[10, 20]}
        showInfo={true} />


            {/* <Scrolling mode="virtual" useNative='false' showScrollbar='never' scrollByContent='true' scrollByThumb='true' /> */}
        <GroupPanel visible={true} />
        <FilterRow visible={true} />
        <FilterPanel visible ={true} />
        <ColumnChooser enabled={true} />
 
        <Column dataField={'id'}
         caption='Request'
         cellTemplate={'linkCellTemplate'}
         allowFiltering={false}
         allowSorting={false}
         allowSearch={false} doViewChangeClicked={this.viewChangeClickHandler} dataType='number' visible={true} />
         
        <Column dataField={'chainStatus'} caption='Status' dataType='number' visible={true} >
            <ColumnLookup dataSource={lookupData.statusTypes} valueExpr='id' displayExpr='text' visible={true} />
        </Column> 
      
   

        <Column dataField={'documentIdentifier'} caption='Identifier' dataType='string' visible={true} />
        <Column dataField={'documentTitle'} caption='Title' dataType='string' visible={true} />
        <Column dataField={'originator'} dataType='string' visible={true}/>
        <Column dataField={'changeType'} dataType='number' visible={true}>
            <ColumnLookup dataSource={lookupData.changeTypes} valueExpr='id' displayExpr='text' visible={true} />
        </Column>   
        <Column dataField={'dateRequested'} dataType="date" format="dd/MM/y" visible={true} />
 

         <Column type={'buttons'} width={75} allowReordering={false} showInColumnChooser={false} allowResizing={false}
          buttons={[ {
            text: 'Approve/Reject',
            hint: 'Approve/Reject',
            icon: 'ion ion-md-checkmark-circle-outline',
            onClick: this.approvalClickHandler
          },
         
          ]} />
         <Template name={'linkCellTemplate'} render={RequestLinkCell} />
        </DataGrid>
        </Holder>;

               

        let approvalPopup = <ApprovalPopup
        popupVisible={this.props.showingApprovalPopup}
       // rowData={this.props.rowData}
        closeOnOutsideClick={true}
        hidingApprovalPopupHandler={this.hidingApprovalPopupHandler}
        approver={true}
        >
        </ApprovalPopup>

        if(this.props.showingApprovalPopup === false)
        {
            approvalPopup = null;
        }

        let viewChangePopup = <ViewChangePopup
        popupVisible={this.props.showingChangeRequestPopup}
       // rowData={this.props.rowData}
        closeOnOutsideClick={true}
        hidingChangeRequestPopupHandler={this.hidingChangeRequestPopupHandler}
        approver={true}
        >
        </ViewChangePopup>

        if(this.props.showingChangeRequestPopup === false)
        {
            viewChangePopup = null;
        }
        
        return( 
        <Holder>
            {grid}
            {approvalPopup}
            {viewChangePopup}
        </Holder>)
        
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.oidc.user,
        showingApprovalPopup: state.changeControl.showingApprovalPopup,
        showingChangeRequestPopup: state.changeControl.showingChangeRequestPopup,
        pagingEnabled: state.prefs.pagingEnabled
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onShowingViewChangePopup: (rowData) => dispatch(actions.showViewChangePopup(rowData)),
        onShowingApprovalPopup: (rowData) => dispatch(actions.showApprovalPopup(rowData)),
        onHidingApprovalPopup: () => dispatch(actions.hideApprovalPopup()),
        onHidingViewChangeForm: () => dispatch(actions.hideViewChangePopup())
     
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(ChangeApproval);