import React, {PureComponent} from 'react';
import {Popup, ScrollView} from 'devextreme-react';
import { connect } from "react-redux";
import {animOff} from '../../utils/appConfig'
import ViewChangeForm from './ViewChangeForm/ViewChangeForm';

class ViewChangePopup extends PureComponent {
     

        

        render() {

            let requestType = 'Deletion';

            if(this.props.rowData.changeType === 1) {
                requestType = 'Update';
            } else if (this.props.rowData.changeType === 2) {
                requestType = 'New Document';
            }


            return <Popup 
                    visible={this.props.popupVisible} 
                    closeOnOutsideClick={this.props.closeOnOutsideClick} 
                    onHidden={this.props.hidingChangeRequestPopupHandler} 
                    animation={animOff} title={'Change Request Form #' + this.props.rowData.id + ' ' + requestType} showCloseButton={true}>
                    <ScrollView>
                    <ViewChangeForm rowData={ this.props.rowData }/>
                    </ScrollView>
                    </Popup>
        }
}

const mapStateToProps = (state) => {
    return {
        user: state.oidc.user,
        rowData: state.changeControl.rowData
    };
}

export default connect(mapStateToProps)(ViewChangePopup)