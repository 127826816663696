import React, {PureComponent} from 'react';
import {Popup, Button, Form, ScrollView} from 'devextreme-react';
import { connect } from "react-redux";
import * as actions from '../../store/actions/actions';
import Holder from '../../hoc/holder';
import { animOff } from '../../utils/appConfig';
import axios from 'axios';
import notify from 'devextreme/ui/notify';

class ReadSignPopup extends PureComponent {

    state={
        read: null,
    };

    formData = {
        feedback: '',
    };

    componentDidUpdate() {

        if (this.props.rowData !== null) {

            if (true) {
                axios.get('api/rscheck/' + this.props.rowData.id)
                    .then(response => {
                        this.setState({ read: response.data.result });

                    });
            }
        }

    }

    submitFeedback = (e) => {
        if(this.state.read !== null) {
            if(this.state.read === true) {

                var result = e.validationGroup.validate();
                
                var data = {
                    feedback : this.formData.feedback,
                    docId : this.props.rowData.id
                }
                 if(result.isValid) {
                     axios.post('api/readandsign/', data)
                     .then(response => {
                         notify("Feedback submitted","success", 1500); 
                            this.readAndSignAction(this.props.rowData.id);
                            this.formData.feedback = '';
              
                     })
                     .catch(error => {
                         notify("Feedback NOT submitted","warning", 1500); 
                         console.log(error);
                  
                     });
        
                    
                }
            }
            else
            {
                notify("You must have recently read the document in order to \"sign\"","error", 2500); 
            }
        }


     

    
    }


    readAndSignAction = (rowData) => {
        if(this.state.read !== null) {
            if(this.state.read === true) {
                this.props.onReadAndSigning(rowData);
            }
            else
            {
                notify("You must have recently read the document in order to \"sign\"","error", 2500); 
            }
        }
        
        
    }

    formItems = [{
        itemType: 'group',
        name: 'Feedback',
        caption: "This read and sign requires feedback.",
        colCount: 1,
        items: [{
                    dataField: "feedback",
                    editorType: "dxTextArea",
                    // label: { text: 'Please type your feedback below'},
                    editorOptions: {
                        disabled: false,
                        width: "100%",
                        height: 250,
                    },
                    validationRules: [{
                        type: "required",
                        message: "You feedback is Required."
                    },
                    {
                        type: "stringLength",
                        max: 1000,
                        message: "Feedback cannot exceed 1000 characters."      
                    }
                    ]
                },
                
              
                {
                    itemType: "button",
                    visible: true,
                    horizontalAlignment: "right",
                    colSpan: 3,
                    buttonOptions: {
                        text: "Submit",
                        type: "success",
                        useSubmitBehavior: false,
                        onClick: this.submitFeedback
                    }
                },
        ],
    }];


    render() {

        let popup = null;
        

        if(this.props.rowData) {
        
               if(this.props.rowData.feedbackRequired === true)
               {
               popup =   <Popup visible={this.props.popupVisible} title={"Read And Sign " + this.props.rowData.identifier} closeOnOutsideClick={true} onHiding={this.props.hidingPopupHandler} animation={animOff} showCloseButton={true}>
                    <ScrollView width='100%' height='100%'>
                <div>
                <p></p>
                <Form formData={this.formData} items={this.formItems} ref={(form) => {this.feedbackform = form}} >
                </Form>
                </div>
              
                </ScrollView>
                </Popup> 
            }
            else
            {
            popup = <Popup visible={this.props.popupVisible} title={"Read And Sign " + this.props.rowData.identifier} closeOnOutsideClick={true} onHiding={this.props.hidingPopupHandler} animation={animOff} showCloseButton={true}>
                <ScrollView>
            <div>
            <p>You acknowledge that you have read and understand this document.</p>
            <p>Please click Complete if you agree, or cancel if you disagree.</p>
            </div>
          
            <div style={{float : "right", marginTop: "10px"}}>
            <span><Button text="Cancel" type="danger" onClick={this.props.hidingPopupHandler}></Button></span>
            <span style ={{marginLeft: "15px"}}><Button text="Complete" type="success" onClick={() => this.readAndSignAction(this.props.rowData.id)}></Button></span>
            </div>
            </ScrollView>
            </Popup>
            }
        }

        return (
            <Holder>{popup}</Holder>
        );
    }

}

const mapDispatchToProps = dispatch => {
    return {
        onReadAndSigning: (docId) => dispatch(actions.readAndSignAsync(docId)),
    };
  }

 const mapStateToProps = (state) => {
    return {
        rowData:state.document.rowData
    };
}


export default connect(mapStateToProps,mapDispatchToProps)(ReadSignPopup);