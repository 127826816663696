import React, {Component} from 'react';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import * as apis from '../../../../utils/apis';
import { Form, LoadIndicator } from 'devextreme-react';
import { connect } from "react-redux";
import Holder from '../../../../hoc/holder';
import axios from 'axios';
import DraftDownloadButton from '../../../ViewChangePopup/DraftDownloadButton/DraftDownloadButton';

const getSectionsDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: apis.MANUALAPI + 'api/clientsections/',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  const getOwnersDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: apis.MANUALAPI + 'api/clientjobtitles/',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  const getEntitiesDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: apis.MANUALAPI + 'api/companies/getallcompanies',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }
class AddForm extends Component {

    state={
        loading: true,
        addFormData : {
            identifier: '',
            title: '',
            changeReason: '',
            additionalText: '',
            originator: '',
            desiredEntity: '',
            sectionId: '',
            owners: [],
            primaryId: ''

        }
    }

    componentDidMount() {
        axios.get('api/changecontrol/getAddForm' + '?changeId=' + this.props.id)
        .then(response => {
            this.setState({addFormData: response.data, loading: false });
        });
    }

    render() {

        let addFormConfig = {
            items: [
                {
                    colSpan: 1,  
                    dataField: "identifier",
                    editorType: "dxTextBox",
                 
                    editorOptions: {
                        readOnly: true,
                    },
                    label : { text: "Desired Identifier"},
                },
                {
                    colSpan: 1,  
                    dataField: "title",
                    editorType: "dxTextBox",
                  
                    editorOptions: {
                        readOnly: true,
                    },
                    label : { text: "Desired Title"},
                   
                },
                {
                    colSpan: 1,  
                    dataField: "sectionId",
                    editorType: "dxSelectBox",
                
                    editorOptions: {
                        dataSource: getSectionsDataSource(this.props.user.access_token),
                        valueExpr: "id",
                        searchExpr: "fullTitle",
                        displayExpr: "fullTitle",
                        searchEnabled: true,
                        height: "50%",
                        readOnly: true,
                    },
                    label : { text: "Desired Section"},
                   
                },
                {
                    colSpan: 1,  
                    dataField: "desiredEntity",
                    editorType: "dxSelectBox",
               
                    editorOptions: {
                        dataSource: getEntitiesDataSource(this.props.user.access_token),
                        valueExpr: "id",
                        searchExpr: "shortDescription",
                        displayExpr: "shortDescription",
                        searchEnabled: true,
                        height: "50%",
                        readOnly: true,
                    },
                    label : { text: "Desired Doc Entity"},
                   
                },
                {
                    colspan: 1,
                    dataField: "owners",
                    editorType: "dxTagBox",
               
                    editorOptions: {
                        dataSource: getOwnersDataSource(this.props.user.access_token),
                        valueExpr:"id",
                        displayExpr: "roleHolder",
                        searchExpr: "roleHolder",
                        readOnly: true,
                        
                    },
                    label : { text: "Desired Owners"},
                   
                },
                {
                    colspan: 1,
                    dataField: "primaryId",
                    editorType: "dxSelectBox",
                  
                    editorOptions: {
                        dataSource: getOwnersDataSource(this.props.user.access_token),
                        valueExpr:"id",
                        displayExpr: "roleHolder",
                        searchExpr: "roleHolder",
                        readOnly: true,
                    },
                    label : { text: "Desired Primary Owner"},
                   
                },
                {
                    colSpan: 2,  
                    dataField: "changeReason",
                    editorType: "dxTextArea",
                  
                    editorOptions: {
                        height:"100px" ,
                        readOnly: true,
                    },
                    label : { text: "Reason for Addition"},
                   
                },
                {
                    colSpan: 2,
                  
                    dataField: "additionalText",
                    editorType: "dxTextArea",
                  
                    editorOptions: {
                        height:"100px" ,
                        readOnly: true,
                    },
                    label : { text: "Additional Requests"},
                },
                {
                    colSpan: 2,  
                    dataField: "originator",
                    editorType: "dxTextBox",
                   
                    editorOptions: {
                        readOnly: true,
                    },
                    label : { text: "Originator"},
                    
                },
                {
                    itemType: "button",
                    horizontalAlignment: "right",
                    colSpan: 2,
                    buttonOptions: {
                        text: "OK",
                        type: "default",
                        useSubmitBehavior: false,
                        onClick: this.props.formClosed
                    }
                }


            ]
        };


        return <Holder>
        <DraftDownloadButton visible={!this.state.loading} changeId = {this.props.id}/>   
        <Form visible={!this.state.loading}
        height="100%"
        colCount={2}
        formData={this.state.addFormData}
        items={addFormConfig.items}
        ref={(form) => {this.addForm = form}} />
         <LoadIndicator visible={this.state.loading} />
        </Holder>
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.oidc.user,
    };
}

export default connect(mapStateToProps)(AddForm);