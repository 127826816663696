import React, {Component} from 'react';
import { Form } from 'devextreme-react';
import { connect } from "react-redux";
import * as actions from '../../store/actions/actions';
import axios from 'axios';
import Holder from '../../hoc/holder';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';
import * as config from '../../utils/appConfig';

class ApprovalForm extends Component {
   
    submitApprovalRequest = (params) => {

        var result = params.validationGroup.validate();
        
        if(result.isValid) {
            let result = confirm("<i>"+ config.changeStatement + "</i>", "Confirm Change Approval");
            result.then((dialogResult) => {
                console.log(dialogResult);
                if(dialogResult === true) {
                    

                    this.approvalForm.instance.option("formData").changeRequestId = this.props.changeRequestId;
                    this.approvalForm.instance.option("formData").approvedDenied = true;
          
          
                    axios.post('api/changecontrol/actionapprovalitem',this.approvalForm.instance.option("formData") )
                    .then(response => {
                      this.props.onHidingApprovalPopup(); 
                      this.props.onActioningApproval(response.status);  
                      notify("Change Request Approved","success", 1500);   
                    })
                    .catch(error => {
                        console.log(error);
                    });


                }
                else {
                    return false;
                }
            });



         
        }
      }

      submitRejectionRequest = (params) => {
        var result = params.validationGroup.validate();
        
        if(result.isValid) {
            let result = confirm("<i>Are you sure you want to reject this change?</i>", "Confirm Change Rejection");
            result.then((dialogResult) => {
                console.log(dialogResult);
                if(dialogResult === true) {
                    this.approvalForm.instance.option("formData").changeRequestId = this.props.changeRequestId;
                    this.approvalForm.instance.option("formData").approvedDenied = false;
             
               
                    axios.post('api/changecontrol/actionapprovalitem',this.approvalForm.instance.option("formData") )
                    .then(response => {
                      this.props.onHidingApprovalPopup();  
                      this.props.onActioningApproval(response.status);   
                      notify("Change Request Rejected","success", 1500);    
                    })
                    .catch(error => {
                        console.log(error);
                    });
                }
                else
                {
                    return false;
                }

           
        });
      }
    }



    render() {
  
    let approvalFormData = {
        decisionNote: null,
        changeRequestId: null
    }

    let approvalFormConfig = {
        items: [
             {
                dataField: 'decisionNote',
                colSpan: 2,
                editorType: "dxTextArea",
                editorOptions: {
                    height:"150px",
                    placeholder: "Enter approval or rejection reason here..."
                },
                label : { text: "Reason for approval/rejection"},
                validationRules: [{
                    type: "required",
                    message: "Reason for approval/rejection is required"
                },
                {
                    type: "stringLength",
                    max: 4096,
                    message: "Approval/Rejection reason cannot exceed 4096 Characters"      
                }]
            },
            {
                itemType: "group",
                colCount: 8,
                colSpan: 2,
                items: [
                    {
                        itemType: "empty",
                        colSpan: 6
                    },
                    {
                        itemType: "button",
                        horizontalAlignment: "right",
                        colSpan: 1,
                        buttonOptions: {
                            text: "Reject",
                            type: "danger",
                            useSubmitBehavior: false,
                            onClick: this.submitRejectionRequest
                        }
                    },
                    {
                        itemType: "button",
                        horizontalAlignment: "right",
                        colSpan: 1,
                        buttonOptions: {
                            text: "Approve",
                            type: "success",
                            useSubmitBehavior: false,
                            onClick: this.submitApprovalRequest
                        }
                    },
                ]
            },
           
        ]
    };

    return <Holder>
    <Form
     height="100%"
     colCount={2}
     formData={approvalFormData} 
     items={approvalFormConfig.items}
     ref={(form) => {this.approvalForm = form}}>
     </Form>   
     </Holder>

    }
  
}



const mapDispatchToProps = dispatch => {
    return {
        onHidingApprovalPopup: () => dispatch(actions.hideApprovalPopup()),
        onActioningApproval: (serverResponse) => dispatch(actions.actionChangeRequest(serverResponse))
    };
}

export default connect(null,mapDispatchToProps)(ApprovalForm);