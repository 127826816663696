import React, { Component } from 'react';
import { connect } from "react-redux";
import Layout from './components/Layout/Layout';
import DocumentGrid from './containers/DocumentGrid/DocumentGrid';
import UpdateGrid from './containers/UpdateGrid/UpdateGrid';
import ReadAndSignGrid from './containers/ReadAndSignGrid/ReadAndSign';
import ReadAndSignHistory from './containers/ReadAndSignHistory/ReadAndSignHistory';
import ReviewGrid from './containers/ReviewGrid/ReviewGrid';
import CallbackPage from './containers/CallbackPage/CallbackPage';
import HomePage from './containers/HomePage/HomePage';
import MainPage from './components/MainPage/mainPage';
import LogoutPage from './containers/LogoutPage/LogoutPage';


import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.material.purple.light.css';



import './App.css';
import FavouritesGrid from './containers/FavouritesGrid/FavouritesGrid';
import SearchResults from './containers/SearchResults/SearchResults';

import * as actions from './store/actions/actions';
import axios from 'axios';
import MyChanges from './containers/ChangeControl/MyChanges/MyChanges';
import ChangeApproval from './containers/ChangeControl/ChangeApproval/ChangeApproval';
import ReadingListGrid from './containers/ReadingList/ReadingListGrid/ReadingListGrid';
import ReadingListAdmin from './containers/ReadingList/ReadingListAdmin/ReadingListAdmin';
import Preferences from './components/Preferences/Preferences';
import Suggestions from './components/Suggestions/Suggestions';
import Query from './components/Query/Query';
import About from './components/About/About';
import Incident from './containers/Incident/Incident';
import Induction from './containers/Induction/Induction';



class App extends Component {

  
  state= {
    drawerShown: false,
    sectionId : null,
    sectionName : null,
  }

 

  closeMenuHandler = () => {
      this.setState({ drawerShown : false })
  }

  toolBarSelectionHandler = (e) => {

      if(e.itemData.role) {
        switch(e.itemData.role) {
          case 'menu':
            this.setState({ drawerShown : true })
            break;
           case 'incident':
            this.props.history.push('/' + e.itemData.role);
            break;
          case 'logout':
            this.props.history.push('/' + e.itemData.role);
            break;
          case 'feedback':
            this.props.history.push('/' + e.itemData.role);
            break;
          case 'about':
            this.props.history.push('/' + e.itemData.role);
            break;
          default: 
        }
      }
  }

  entityChangedHandler = (e) => {
    //this.setState({ entityId : e.value })
    this.props.onLoggedIn(e.value);
  }
 
  render() {
     axios.defaults.baseURL = 'https://manualapi.bosa.uat99.com/';
    // axios.defaults.baseURL = 'https://manualapi.uat.notreal.com/';
    // axios.defaults.baseURL = 'https://manualapi.tcdemo.uat99.com/';

    const {user} = this.props;
    if(user) {
      axios.defaults.headers.common = {'Authorization': "bearer " + user.access_token}
    }
   

    let view =  <BrowserRouter>
  
      <Layout 
       toolbarMenu={this.props.topMenu}
       toolBarMenuClick={this.toolBarSelectionHandler}
       showDrawer={this.state.drawerShown}
       sectionName={this.state.sectionName}
       entityChanged={this.entityChangedHandler}
       showMenu={this.state.drawerShown} 
       clicked={this.closeMenuHandler}
       activeTab={this.state.activeTab } >
       <Switch>
        <Route path='/' exact component={MainPage} />
        <Route path='/callback' exact component={CallbackPage} />
        <Route path='/logout' exact component ={LogoutPage} />
        <Route path='/home' exact component={HomePage} />
        <Route path='/section' exact component={DocumentGrid} />
        <Route path='/section/:sectionId' exact component={DocumentGrid} />  
        <Route path='/favourites' exact component={FavouritesGrid} />
        <Route path='/updates' exact component={UpdateGrid} />
        <Route path='/readandsign' exact component={ReadAndSignGrid} />
        <Route path='/readinglist' exact component={ReadingListGrid} />
        <Route path='/readinglistadmin' exact component={ReadingListAdmin} />
        <Route path='/reviews' exact component={ReviewGrid} />
        <Route path='/changes' exact component={MyChanges} /> 
        <Route path='/approvals' exact component={ChangeApproval} /> 
        <Route path='/search' exact component={SearchResults} />
        <Route path='/incident' exact component={Incident} /> 
        <Route path='/induction' exact component={Induction} /> 
        <Route path='/rshistory' exact component={ReadAndSignHistory} />
        <Route path='/querytoadmin' exact component={Query} />
        <Route path='/preferences' exact component={Preferences} />
        <Route path='/suggest' exact component={Suggestions} />
        <Route path='/about' exact component={About} />
       </Switch>
      </Layout>  
    </BrowserRouter>;

if(!user || user.expired) {
  view = <BrowserRouter>
   <Switch>
    <Route path='/callback' exact component={CallbackPage} />
    <Route path='/' exact component={MainPage} />
    <Redirect to='/' />
   </Switch>
  </BrowserRouter>
} 

    return (
      <div className="App">
      {view}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      user: state.oidc.user,
      topMenu: state.tabBar.topMenu
  };
}

const mapDispatchToProps = dispatch => {
  return {
      onLoggedIn: (entityId) => dispatch(actions.changeEntity(entityId)),
      onInitBadgeCount: () => dispatch(actions.initBadgeCountsAsync())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
