import React, {Component} from 'react';
import { Form, LoadIndicator } from 'devextreme-react';
import axios from 'axios';
import Holder from '../../../../hoc/holder';
import DraftDownloadButton from '../../../ViewChangePopup/DraftDownloadButton/DraftDownloadButton';

class UpdateForm extends Component {

    state= {
        loading: true,
        updateFormData: {
            identifier: '',
            title: '',
            changeReason: '',
            additionalText: '',
            originator: '',
        }
    }

    componentDidMount() {
        axios.get('api/changecontrol/getupdateForm' + '?changeId=' + this.props.id)
        .then(response => {
            this.setState({updateFormData: response.data, loading: false });
        });
    }


    render() {

        let updateFormConfig = {
            items: [
                {
                    colSpan: 1,  
                    dataField: "identifier",
                    editorType: "dxTextBox",
                    readOnly: true,
                    editorOptions: {
                        
                    },
                    label : { text: "Identifier"},
                    
                },
                {
                    colSpan: 1,  
                    dataField: "title",
                    editorType: "dxTextBox",
                    readOnly: true,
                    editorOptions: {
                        
                    },
                    label : { text: "Title"},
                   
                },
                {
                    colSpan: 2,
                  
                    dataField: "changeReason",
                    editorType: "dxTextArea",
                    readOnly: true,
                    editorOptions: {
                        height:"100px" 
                    },
                    label : { text: "Reason for Change"},
                },
                {
                    colSpan: 2,
                  
                    dataField: "additionalText",
                    editorType: "dxTextArea",
                    readOnly: true,
                    editorOptions: {
                        height:"100px" 
                    },
                    label : { text: "Additional Requests"},
                },
                {
                    colSpan: 2,  
                    dataField: "originator",
                    editorType: "dxTextBox",
                    readOnly: true,
                    editorOptions: {
                        
                    },
                    label : { text: "Originator"},
                    
                },
                {
                    itemType: "button",
                    horizontalAlignment: "right",
                    colSpan: 2,
                    buttonOptions: {
                        text: "OK",
                        type: "default",
                        useSubmitBehavior: false,
                        onClick: this.props.formClosed
                    }
                }

            ]
        };

        return <Holder>
        <DraftDownloadButton visible={!this.state.loading} changeId = {this.props.id}/>   
        <Form visible={!this.state.loading}
        height="100%"
        colCount={2}
        formData={this.state.updateFormData}
        items={updateFormConfig.items}
        ref={(form) => {this.changeForm = form}} />
        <LoadIndicator visible={this.state.loading} />
        </Holder>
        
    }
}

export default UpdateForm;