import React, {PureComponent} from 'react';
import {Popup, Button} from 'devextreme-react';
import * as actions from '../../store/actions/actions';
import { connect } from "react-redux";
import { animOff } from '../../utils/appConfig';

class RemoveFavouritePopup extends PureComponent {

    render() {
        return (
            <Popup visible={this.props.popupVisible} title={"Remove Favourite " + this.props.rowData.identifier} closeOnOutsideClick={true} onHiding={this.props.hidingPopupHandler} animation={animOff} showCloseButton={true}>
       
            <div>
            <p>You are removing {this.props.rowData.identifier} - {this.props.rowData.title} for entity {this.props.rowData.companyShortDescription} held in section {this.props.rowData.sectionTitle} from your favourites</p>
            <p>Please click Add if you want to do this, or cancel if you disagree.</p>
            </div>
            <div style={{float : "right", marginTop: "10px"}}>
            <span><Button text="Cancel" type="danger" onClick={this.props.hidingPopupHandler}></Button></span>
            <span style ={{marginLeft: "15px"}}><Button text="Remove" type="success" onClick={() => this.props.onRemovingFavourite(this.props.rowData.id)}></Button></span>
            </div>
            </Popup>
        );
    }

}

const mapDispatchToProps = dispatch => {
    return {
        onRemovingFavourite: (docId) => dispatch(actions.removeFavouriteAsync(docId)),
    };
  }

 const mapStateToProps = (state) => {
    return {
        rowData:state.document.rowData
    };
}


export default connect(mapStateToProps,mapDispatchToProps)(RemoveFavouritePopup);