import React from 'react';
import Holder from '../../hoc/holder';

import './SearchCommandCell.css';


const searchCommandCell = (props) => {
    return (
        
        <Holder>
            <i className="ion ion-md-heart mslsearch" onClick={() => props.favouriteClicked(props.result) }></i>         
            <i className="dx-icon-box mslsearch"  onClick={(e) => props.historyClicked(props.result)}></i>
            <i className="dx-icon-card mslsearch"  onClick={(e) => props.ownerClicked(props.result)}></i>
            {props.result.primary === '00000000-0000-0000-0000-000000000000' ? <i></i> : <i className="ion ion-md-document mslsearch" onClick={(e) => props.changeClicked(props.result)} ></i>}
            
        </Holder>
    );

}

export default searchCommandCell;