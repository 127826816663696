import * as actionTypes from '../actions/actionTypes';

const initialState = {
    topMenu : [
        {     
            id: 1,
            text: "Favourites", 
            icon: "favorites", 
            visible: true,
            badge: 0,
            role: "favourites" 
        },
        { 
            id: 2,
            text: "Updates", 
            icon: "info", 
            visible: true,
            badge: 0,
            role: "updates"
        },
        { 
            id: 3,
            text: "Read & Sign", 
            icon: "check", 
            visible: true,
            badge: 0,
            role: "readandsign" 
        },
        { 
            id: 4,
            text: "Reading List", 
            icon: "doc", 
            visible: true,
            role: "readinglist" 
        },
        { 
            id: 5,
            text: "Reviews", 
            icon: "todo", 
            visible: true,
            badge: 0,
            role: "reviews" 
        },
        { 
            id: 6,
            text: "Changes", 
            icon: "cart", 
            visible: true,
            badge: 0,
            role: "changes" 
        },
      ],
}

const tabBarReducer = (state = initialState, action) => {
    switch(action.type) {
        
         case actionTypes.ADD_FAVOURITE:
            const newMenu = [...state.topMenu];
            if(action.val === 200) {
            
                newMenu[0].badge = parseInt(newMenu[0].badge) + 1;
            }

            return {
                ...state,
                topMenu : newMenu
            }
         
         case actionTypes.REMOVE_FAVOURITE:
            const updMenu = [...state.topMenu];
            if(action.val === 200) {
            
                updMenu[0].badge = parseInt(updMenu[0].badge) - 1;
            }
            return {
                ...state,
                topMenu: updMenu
            }
         
         case actionTypes.READ_AND_SIGN:
            const rasMenu = [...state.topMenu];
            if(action.val === 200) {
            
                rasMenu[2].badge = parseInt(rasMenu[2].badge) - 1;
            }
            return {
                ...state,
                topMenu: rasMenu
            }

        case actionTypes.ACTION_CHANGE_REQUEST:
            const appMenu = [...state.topMenu];
            if(action.val === 200) {
                appMenu[5].badge = parseInt(appMenu[5].badge) - 1;
            }
            return {
                ...state,
                topMenu: appMenu
            }

         case actionTypes.SET_BADGE_COUNTS:
            //console.log("badgecounts", action.badgeCounts)
            const initMenu = [...state.topMenu];
            initMenu[0].badge = action.badgeCounts.favouriteCount;
            initMenu[1].badge = action.badgeCounts.updateCount;
            initMenu[2].badge = action.badgeCounts.readAndSignCount;
            initMenu[3].badge = action.badgeCounts.readingListCount;
            initMenu[4].badge = action.badgeCounts.docReviewCount;
            initMenu[5].badge= action.badgeCounts.changeApprovalCount;

            return {
                ...state,
                topMenu: initMenu
            }

         case actionTypes.REVIEW_DOC:
            const reviewMenu = [...state.topMenu];
            if(action.daysRemaining <= 30) {
                reviewMenu[4].badge = parseInt(reviewMenu[4].badge) - 1;
            }
         return {
             ...state,
             topMenu: reviewMenu
         }
         default:
         return state;
    }
};

export default tabBarReducer;