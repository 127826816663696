import React from 'react';
import Holder from '../../../hoc/holder';
import { Button } from 'devextreme-react';
import './ApprovalLine.css'

const approvalLine = (data) => {

    const buttonTypes = {WAITING:"default", ACCEPTED:"success", REJECTED:"danger"};

    return (
        <Holder>
            <div>
            {data.data.isPrimary ? <Button icon="primaryOwner" disabled={true}></Button> : <Button icon="user" disabled={true}></Button>} <Button type={buttonTypes[data.data.lineStatus]} width="150px">{data.data.lineStatus}</Button>  {data.data.jobTitle}
        
           {/* {data.isPrimary ? <Button icon="primaryOwner" type="success" disabled={true}></Button> : <Button icon="user" disabled={true} type={'default'}></Button>} {data.jobTitle} */}
            </div>
            {data.data.decisionNote !== '' ? <div><p className={'wrappable'}>Reason: {data.data.decisionNote}</p></div> : null }
            
        </Holder>
    );

}

export default approvalLine;