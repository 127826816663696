import React from 'react';
import Holder from '../../hoc/holder';
import './LinkCell.css';
import axios from 'axios';

const linkcell = (cellData) => {
    return (
        <Holder>
            <a className='msl-doclink' onClick={(e) => getDocLink(cellData.data.id,e)}>{cellData.value}</a> 
        </Holder>
    );

}

const getDocLink = (docId,e) => {
 e.preventDefault();

 // code here to fetch link and redirect to it.
//  let bodyFormData = new FormData();
//  bodyFormData.set('id',docId);

 let dlRequest = {
     id: docId,
     client: true
 }

 axios.post('api/downloadlink/getdownloadlink',dlRequest)
 .then(response => {
     //window.location.href = (response.data);
     window.open(response.data, '_blank');
 })
 .catch(error => {
     console.log(error)
 });
}

export default linkcell;